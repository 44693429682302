import { ArrowBack } from "@mui/icons-material";
import React, { ChangeEvent, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CompanyLogo, Button } from "../../../Components/Atoms";
import { Container, Content, VerificationContainer } from "./styles";
import { AuthContext } from "../../../Contexts/Auth";
import { Grid, Input } from "@mui/material";
import useCallAxios from "../../../Hooks/useCallAxios";
// import MetaDecorator from "../../components/MetaDecorator";

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const { firstStepData } = stateAuthContext;

  const [email, setEmail] = useState("");

  const {
    fetchPOST: fetchResetPassword,
    requestLoading: loading,
    requestData: operatorsListResponse,
  } = useCallAxios({ context: "reset" });

  return (
    <Container>
      <Content>
        <VerificationContainer>
          <CompanyLogo width={200} height={60} />
          <p>Esqueceu sua senha?</p>
          <p>Informe seu e-mail abaixo para redefinir sua senha:</p>
          <Grid container spacing={2}>
            <Grid item>
              <Input
                value={email}
                type="email"
                name="email"
                placeholder="E-mail"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Button onClick={() => alert(email)}>Redefinir senha</Button>
            </Grid>
          </Grid>
          <Button
            action={"return"}
            onClick={() => {
              history.push("/");
              dispatchAuthContext({
                type: "clear",
              });
            }}
          >
            Voltar
          </Button>
        </VerificationContainer>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
