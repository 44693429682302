import { IconButton } from "@mui/material";
import styled, { css } from "styled-components";

interface IProps {
  noModal: boolean;
}

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* min-width: 500px; */
  overflow-y: hidden;

  ${(props) =>
    props.noModal &&
    css`
      color: rgba(0, 0, 0, 0.87);
      box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
      background-color: #fff;
      width: 50%;
      border-radius: 10px;
      margin: 0 auto;
    `}
  div {
    ${(props) =>
      props.noModal &&
      css`
        border-radius: 10px 10px 0px 0px;
      `}
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  overflow-y: auto;
  height: 100%;
  max-width: 400px;
  border: 2px dashed #747474;
  margin: 12px;
  border-radius: 5px;
`;

export const Border = styled.div`
  height: 4px;
  display: flex;
  border-bottom: 2px dashed #747474;
`;

export const ReceiptHeader = styled.div`
  background: ${(props) => props.theme.background.original};
  color: #2f3e9e;
  height: 75px;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 10px;

  svg {
    color: white;
    font-size: 30px;
  }
`;

export const ReceiptTitle = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;

  div {
    text-align: center;
    gap: 10px;
    h2,
    h3,
    h4 {
      margin-bottom: 15px;
    }
    h2 {
      color: ${(props) => props.theme.primary.original};
      font-size: 40px;
      font-weight: 700;
    }
    h3 {
      color: ${(props) => props.theme.primary.original};
      font-size: 24px;
      font-weight: 300;
    }
    h4 {
      color: #747474;
      font-size: 16px;
      font-weight: 300;
    }
  }
  hr {
    border: 1px solid #f2f2f2;
    height: 2px;
    width: 100%;
  }
`;

export const ReceiptDetailsContent = styled.div`
  div {
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* margin-bottom: 20px; */
    font-family: monospace !important;
    white-space: break-spaces;
    word-break: break-word !important;

    h4 {
      font-size: 16px;
      font-weight: 700;
      color: #444;
      word-break: break-word;
      /* text-align: left; */
    }
    p {
      /* font-size: 20px; */
      /* color: ${(props) => props.theme.primary.original}; */
      font-weight: 400;
      word-break: break-word;
    }
    span {
      color: #333;
      font-size: 14px;
    }
  }
`;

export const ReceiptFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: monospace !important;
  hr {
    border: 1px solid #f2f2f2;
    height: 2px;
    width: 100%;
    margin-bottom: 20px;
  }

  hr + p {
    font-weight: 700;
  }

  p {
    color: #444;
    font-size: 14px;
    line-height: 18px;
  }

  hr + p + p {
    color: #777;
  }

  p + p + p {
    font-weight: 700;
    font-size: 16px;
  }
`;

export const IconBtn = styled(IconButton)`
  color: white;
  margin-left: 10px;
  :hover {
    background-color: #fefefe;
  }
`;

export const Footer = styled.footer`
  position: sticky;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
  box-shadow: 2px 2px 2px 2px #cecece;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    font-size: 16px;
    color: #747474;
    cursor: pointer;
  }
`;
