import React, {
  ReactComponentElement,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { DialogBox, DialogContainer } from "../../Atoms/BalanceWarning/styles";
import {
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";

import manageBalance from "../../../Assets/manage_balance.png";
import { useToast } from "../../../Hooks/toast";
import { Button } from "../../Atoms";
import { OrganizerCardDetail } from "../../../Pages/Private/Organizers/styles";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  formatCurrency,
  simpleConvertToCents,
} from "../../../Utils/formatters";
import {
  formatInputValue,
  maskToDecimal,
} from "../../../Utils/formatInputValue";
import { useAccountBalanceData } from "../../../Hooks";
import { Error } from "../../Atoms/Input/styles";
import {
  useSubAccountAmountAdd,
  useSubAccountAmountWithdraw,
} from "../../../Hooks/useSubAccounts";
import { useHistory } from "react-router-dom";

type MoneyType = "add-amount" | "withdraw-amount" | "";

interface IManageBalanceModalProps {
  pointSaleName: string;
  children: any;
  pointSaleAmount: number;
  subAccountId: string;
}

const eventRefreshBalance = new Event("refresh-balance");

const useManageBalanceModal = ({ subAccountId, pointSaleAmount }) => {
  const { addToast } = useToast();

  const [amount, handleAmount] = useState("");
  const [moneyType, handleMoneyType] = useState<MoneyType>("");

  const { balanceValue, handleGET: fetchAccountBalanceGET } =
    useAccountBalanceData();

  const {
    handlePOST: addAmountSubAccount,
    success: successAddAmountSubAccount,
    loading: loadingAddAmountSubAccount,
  } = useSubAccountAmountAdd();

  const {
    handlePOST: withdrawAmountSubAccount,
    success: successWithdrawAmountSubAccount,
    loading: loadingWithdrawAmountSubAccount,
  } = useSubAccountAmountWithdraw();

  const handleOpenModal = (moneyType: MoneyType) => {
    handleMoneyType(moneyType);
  };

  const handleCloseModal = () => {
    handleMoneyType("");
    handleAmount("");
  };

  const open = Boolean(moneyType);

  const moneyTypeLabel = {
    "add-amount": "Adicionar valor",
    "withdraw-amount": "Resgatar valor",
  };

  const isErrorAdd = simpleConvertToCents(amount) > balanceValue;
  const isErrorWithDraw = simpleConvertToCents(amount) > pointSaleAmount;

  const onSubmit = () => {
    //Caso o valor do input seja 0 ou invalido! nao fazer request
    if (!simpleConvertToCents(amount)) return;
    if (moneyType === "add-amount") {
      try {
        addAmountSubAccount({
          id: subAccountId,
          amount: Number(simpleConvertToCents(amount)),
        });
      } catch (error) {
        console.log(error);
      }
    }
    if (moneyType === "withdraw-amount") {
      try {
        withdrawAmountSubAccount({
          id: subAccountId,
          amount: Number(simpleConvertToCents(amount)),
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    fetchAccountBalanceGET();
  }, []);

  useEffect(() => {
    if (!isErrorAdd && successAddAmountSubAccount.POST) {
      addToast({
        title: "Successo",
        description: `O valor de ${amount} foi transferido com sucesso para o ponto de venda.`,
        type: "success",
      });
      // history.replace(`/pontos-de-venda?ponto=${subAccountId}`);
      window.dispatchEvent(eventRefreshBalance);
      handleCloseModal();
    }
  }, [successAddAmountSubAccount.POST]);

  useEffect(() => {
    if (!isErrorWithDraw && successWithdrawAmountSubAccount.POST) {
      addToast({
        title: "Successo",
        description: `O valor de ${amount} foi resgatado com sucesso para sua conta principal.`,
        type: "success",
      });
      // history.replace(`/pontos-de-venda?ponto=${subAccountId}`);
      window.dispatchEvent(eventRefreshBalance);
      handleCloseModal();
    }
  }, [successWithdrawAmountSubAccount.POST]);

  return {
    open,
    moneyType,
    moneyTypeLabel,
    amount,
    isErrorAdd,
    isErrorWithDraw,
    balanceValue,
    loadingAddAmountSubAccount,
    loadingWithdrawAmountSubAccount,
    handleAmount,
    onSubmit,
    handleCloseModal,
    handleOpenModal,
  };
};

const ManageBalanceModal = (props: IManageBalanceModalProps) => {
  const { pointSaleName, pointSaleAmount, children, subAccountId } = props;
  const {
    open,
    moneyType,
    amount,
    moneyTypeLabel,
    balanceValue,
    isErrorAdd,
    isErrorWithDraw,
    loadingAddAmountSubAccount,
    loadingWithdrawAmountSubAccount,
    handleCloseModal,
    handleAmount,
    handleOpenModal,
    onSubmit,
  } = useManageBalanceModal({ subAccountId, pointSaleAmount });
  return (
    <>
      <DialogContainer open={open} onClose={handleCloseModal}>
        <DialogTitle>
          {moneyTypeLabel[moneyType]} - <strong>{pointSaleName}</strong>
        </DialogTitle>
        <DialogBox>
          <Grid
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            xs={12}
            sx={{ padding: 2 }}
            spacing={2}
          >
            <Grid item xs={12} justifyContent="center">
              <Typography variant="body1">
                Escolha o quanto deseja transferir de sua conta principal para o
                ponto de venda selecionado.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={manageBalance} width={300} />
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Typography>
                Após inserir a quantia desejada clique em
                <strong> {moneyTypeLabel[moneyType]}</strong>
              </Typography>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <OrganizerCardDetail>
                <Grid
                  container
                  xs={12}
                  justifyContent="center"
                  sx={{ padding: 1 }}
                  flexDirection="column"
                  spacing={2}
                >
                  {moneyType === "add-amount" && (
                    <>
                      <Grid item>
                        <strong>
                          Saldo principal: {formatCurrency(balanceValue)}
                        </strong>
                      </Grid>
                      <Grid item>
                        <strong style={{ fontSize: 12 }}>
                          Saldo do ponto de venda:{" "}
                          {formatCurrency(pointSaleAmount)}
                        </strong>
                      </Grid>
                    </>
                  )}
                  {moneyType === "withdraw-amount" && (
                    <>
                      <Grid item>
                        <strong>
                          Saldo do ponto de venda:
                          {formatCurrency(pointSaleAmount)}
                        </strong>
                      </Grid>
                      <Grid item>
                        <strong style={{ fontSize: 12 }}>
                          Saldo principal: {formatCurrency(balanceValue)}
                        </strong>
                      </Grid>
                    </>
                  )}
                  <Grid item>
                    <TextField
                      label={`Transferir`}
                      value={amount}
                      error={
                        (moneyType === "add-amount" && isErrorAdd) ||
                        (moneyType === "withdraw-amount" && isErrorWithDraw)
                      }
                      onChange={(e) =>
                        handleAmount(formatInputValue(e.target.value))
                      }
                      InputProps={{
                        endAdornment: (
                          <CurrencyExchangeIcon
                            color={
                              (isErrorAdd ? "error" : "primary") ||
                              (isErrorWithDraw ? "error" : "primary")
                            }
                          />
                        ),
                      }}
                      sx={{ width: 400 }}
                    />
                  </Grid>
                  {moneyType === "add-amount" && isErrorAdd && (
                    <Grid item>
                      <Error style={{ color: "red" }}>
                        O saldo desejado é maior que o valor na sua conta
                        principal.
                        <br />
                        Saldo principal:
                        <strong> {formatCurrency(balanceValue)}</strong>
                      </Error>
                    </Grid>
                  )}
                  {moneyType === "withdraw-amount" && isErrorWithDraw && (
                    <Grid item>
                      <Error style={{ color: "red" }}>
                        O saldo desejado é maior que o valor no seu ponto de
                        venda.
                        <br />
                        Saldo ponto de venda:
                        <strong> {formatCurrency(pointSaleAmount)}</strong>
                      </Error>
                    </Grid>
                  )}
                </Grid>
              </OrganizerCardDetail>
            </Grid>
          </Grid>
        </DialogBox>
        <DialogActions>
          <Button action="return" onClick={handleCloseModal}>
            Fechar
          </Button>
          <Button
            onClick={onSubmit}
            loading={
              loadingAddAmountSubAccount.POST ||
              loadingWithdrawAmountSubAccount.POST
            }
            disabled={
              loadingAddAmountSubAccount.POST ||
              loadingWithdrawAmountSubAccount.POST
            }
          >
            {moneyTypeLabel[moneyType]}
          </Button>
        </DialogActions>
      </DialogContainer>
      {children({ handleOpenModal })}
    </>
  );
};

export default ManageBalanceModal;
