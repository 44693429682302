import { ArrowBack } from "@mui/icons-material";
import { Button } from "./styles";

export const BackButton = ({ handleClose }) => {
  return (
    <Button onClick={handleClose}>
      <ArrowBack></ArrowBack>
      Voltar
    </Button>
  );
};
