import {
  Avatar,
  DialogActions,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  DialogContainer,
  DialogBox,
} from "../../../../Components/Atoms/BalanceWarning/styles";
import VerificationCode from "react-input-verification-code";
import { Button } from "../../../../Components/Atoms";
import LockIcon from "@mui/icons-material/Lock";
import { AuthContext } from "../../../../Contexts/Auth";
import { VerificationContainer } from "../../../Public/VerificationCode/styles";
import { Countdown } from "../../../../Components/Molecules";

import unlockPin from "../../../../Assets/unlock_pin.png";
import { usePinContext } from "../../../../Contexts/PinContext";
import useCallAxios from "../../../../Hooks/useCallAxios";
import { useToast } from "../../../../Hooks/toast";
import { loadItem } from "../../../../Utils";

const userId = loadItem("userId");

export const useModalPin = () => {
  const { addToast } = useToast();

  const [step, handleStep] = useState("initial");
  const [open, setOpen] = useState(false);
  const [pin, handlePin] = useState("");
  const [operatorId, handleOperatorId] = useState("");
  const [code, setcode] = React.useState("");
  const [verificationDisable, setVerificationDisable] = React.useState(false);
  const { isCreateRequiredPin, handleIsCreateRequiredPin } = usePinContext();

  const [stateAuthContext] = useContext(AuthContext);
  const { firstStepData } = stateAuthContext;
  const { last_number } = firstStepData;

  const handleOpenModal = (id) => {
    setOpen(true);
    handleOperatorId(id);
  };
  const handleCloseModal = () => {
    handlePin("");
    setOpen(false);
  };

  const {
    fetchPUT: createPinOperator,
    requestLoading: loadingCreatePinOperator,
    requestData: createPinOperatorResponse,
    requestSuccess: SuccessCreatePinOperator,
  } = useCallAxios({ context: "operators" });

  const {
    fetchPOST: getTokenAuth,
    requestLoading: loadingGetTokenAuth,
    requestData: GetTokenAuthResponse,
    requestSuccess: SuccessGetTokenAuth,
  } = useCallAxios({ context: "getToken" });

  const onSubmit = () => {
    createPinOperator({
      id: userId,
      afterID: "pin",
      body: { pin, token: code },
    });
  };

  useEffect(() => {
    if (SuccessCreatePinOperator.PUT) {
      addToast({
        title: "Successo",
        description: "Pin criado com sucesso.",
        type: "success",
      });
      handleCloseModal();
      handleIsCreateRequiredPin();
    }
  }, [SuccessCreatePinOperator.PUT]);

  useEffect(() => {
    if (isCreateRequiredPin) {
      handleOpenModal();
    }
  }, [isCreateRequiredPin]);

  useEffect(() => {
    if (step === "token") {
      getTokenAuth({ headers: { Origin: "primebanco.brasilcash.com.br" } });
    }
  }, [step]);

  return {
    open,
    pin,
    step,
    last_number,
    code,
    loadingCreatePinOperator,
    handleCloseModal,
    setcode,
    handlePin,
    onSubmit,
    getTokenAuth,
    setVerificationDisable,
    handleStep,
  };
};

const ModalPin = () => {
  const {
    open,
    pin,
    step,
    last_number,
    code,
    loadingCreatePinOperator,
    handleCloseModal,
    setcode,
    handlePin,
    onSubmit,
    getTokenAuth,
    setVerificationDisable,
    handleStep,
  } = useModalPin();
  return (
    <DialogContainer open={open} onClose={() => {}}>
      {step === "initial" && (
        <>
          <DialogTitle>Criar pin transacional</DialogTitle>
          <VerificationContainer>
            <DialogBox>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                xs={12}
                sx={{ padding: 2 }}
                spacing={2}
              >
                <Grid container xs={12} justifyContent="center">
                  <Typography variant="body1">
                    Para que você consiga realizar transações, precisamos criar
                    a sua senha transacional.
                  </Typography>
                </Grid>
                <Grid item>
                  <img src={unlockPin} width={250} />
                </Grid>
                <Grid container xs={12} justifyContent="center">
                  <Typography>
                    Clique no botão <strong>Começar</strong> para cadastrar sua
                    senha de 4 digitos.
                  </Typography>
                </Grid>
              </Grid>
            </DialogBox>
            <DialogActions>
              <Button
                action="return"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                Cancelar
              </Button>
              <Button onClick={() => handleStep("token")}>Começar</Button>
            </DialogActions>
          </VerificationContainer>
        </>
      )}
      {step === "token" && (
        <>
          <DialogTitle>Verificação em duas etapas</DialogTitle>
          <VerificationContainer>
            <DialogBox>
              <Grid
                container
                flexDirection="column"
                alignItems="center"
                xs={12}
                sx={{ padding: 3 }}
                spacing={2}
              >
                <Grid container xs={12} justifyContent="center">
                  <Typography>
                    Para sua segurança, insira o código de verificação enviada
                    para o número de telefone {last_number}
                  </Typography>
                </Grid>
                <Grid item>
                  <div className="custom-styles">
                    <VerificationCode
                      onChange={setcode}
                      value={code}
                      length={6}
                      placeholder=""
                    />
                  </div>
                </Grid>
                <Grid item>
                  <Countdown
                    expireTime={45}
                    resendCode={getTokenAuth}
                    isExpired={(isExpired) => {
                      setVerificationDisable(isExpired);
                    }}
                  />
                </Grid>
              </Grid>
            </DialogBox>
            <DialogActions>
              <Button
                action="return"
                onClick={() => {
                  handleStep("initial");
                }}
              >
                Voltar
              </Button>
              <Button
                onClick={() => {
                  if (code.length === 6) {
                    handleStep("pin");
                  }
                }}
                disabled={code.length !== 6}
              >
                Avançar
              </Button>
            </DialogActions>
          </VerificationContainer>
        </>
      )}
      {step === "pin" && (
        <>
          <DialogTitle>Criar Pin</DialogTitle>
          <DialogBox>
            <Grid
              container
              flexDirection="column"
              alignItems="center"
              xs={12}
              sx={{ padding: 4 }}
              spacing={2}
            >
              <Grid item>
                <Avatar sx={{ width: 150, height: 150 }}>
                  <LockIcon sx={{ width: "80%", height: "80%" }} />
                </Avatar>
              </Grid>
              <Grid item>
                <VerificationCode
                  onChange={handlePin}
                  value={pin}
                  length={4}
                  placeholder=""
                />
              </Grid>
            </Grid>
          </DialogBox>
          <DialogActions>
            <Button
              action="return"
              onClick={() => {
                handleStep("token");
              }}
            >
              Voltar
            </Button>
            <Button
              onClick={onSubmit}
              loading={loadingCreatePinOperator.PUT}
              disabled={loadingCreatePinOperator.PUT}
            >
              Criar pin
            </Button>
          </DialogActions>
        </>
      )}
    </DialogContainer>
  );
};

export default ModalPin;
