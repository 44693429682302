import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  margin-top: 1rem;
  width: 100%;
`;

export const FilterButton = styled.button`
  display: flex;
  align-items: center;

  gap: 0.5rem;
  width: fit-content;
  height: fit-content;
  padding: 0.5rem 1rem;

  background: #303f9f;
  color: white;
  font-weight: bold;

  border-radius: 0.3rem;
  border: 1px solid #303f9f;

  &:hover,
  &:focus,
  &:active {
    filter: brightness(0.9);
  }
`;

export const CollapseContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 1rem 0;
  padding: 1rem 2rem 0rem 2rem;
  gap: 1rem;
`;

export const CloseButton = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  top: -1rem;
  right: 1rem;

  background: transparent;

  border: none;

  color: #0e0e0e99;

  svg {
    font-size: 1.2em;
  }

  &:hover,
  &:focus {
    font-weight: bold;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  width: 100%;

  border: 1px solid #dfdfdf;
  border-radius: 0.3rem;

  padding: 1rem;
  h3 {
    font-size: 1em;
  }
`;

export const TagFilter = styled.div`
  position: relative;
  width: fit-content;
  font-size: 0.6em;
  background: #eaeaea;

  padding: 0.2rem 1.5rem 0.2rem 0.5rem;

  border-radius: 0.3rem;

  p:first-child {
    font-weight: bold;
  }

  p {
    color: #4d4d4d;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;

    border: none;

    svg {
      font-size: 1.2em;
    }
  }
`;
