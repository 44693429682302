import { Grid } from "@mui/material";
import { formatCurrency } from "../../../../Utils/formatters";
import {
  OrganizerCardContainer,
  OrganizerCardStatus,
  OrganizerCardUpperBar,
} from "./styles";

interface OrganizerCardProps {
  name: string;
  value: number;
  totalValue: number;
  upperBarColor: string;
  statusColor: string;
}

export function OrganizerCard(props: Partial<OrganizerCardProps>) {
  const value =
    props.value !== undefined
      ? formatCurrency(props.value)
      : "Sem valor definido";

  return (
    <OrganizerCardContainer>
      <OrganizerCardUpperBar bgColor={props.upperBarColor} />
      <div style={{ justifyContent: "space-between", display: "flex" }}>
        <h3>{props.name}</h3>
        <OrganizerCardStatus bgColor={props.statusColor} />
      </div>
      <p style={{ margin: "1rem 0" }}>
        {props.value !== undefined ? (
          <span> Valor: {value} </span>
        ) : (
          <span> &nbsp; </span>
        )}
      </p>
      <Grid container>
        <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
          {formatCurrency(props.totalValue)}
        </p>
      </Grid>
    </OrganizerCardContainer>
  );
}
