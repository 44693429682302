import { useRef, useState, useCallback } from "react";
import { Typography } from "@mui/material";
import paymentsImg from "../../../Assets/payments.svg";
import searchImg from "../../../Assets/search.svg";
import { useStyles } from "./styles";
import * as Yup from "yup";
import { ArrowBack } from "@mui/icons-material";
import { Form } from "@unform/web";
import { Button, Input } from "../../Atoms";
import { formatInputValue } from "../../../Utils/formatInputValue";
import { FiSearch } from "react-icons/fi";
import { PasswordConfirm } from "../../../Components/Molecules/PasswordConfirm";
import { validateCPF_CNPJ } from "../../../Utils/CPF_CNPJValidation";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { useTransferTransaction } from "../../../Hooks/TransferTransaction";
import { useBrasilCashAccount } from "../../../Hooks/BrasilCashAccounts";
import { cleanTaxIDMask, handleTaxIDInput } from "../../../Utils/masks";
import { useAccountBalanceData } from "../../../Hooks";
import { useEffect } from "react";
import { convertToCents, stringToDecimal } from "../../../Utils/formatters";
import { loadItem } from "../../../Utils";
import { ModalLoader } from "../../Atoms/ModalLoader";
import { useToast } from "../../../Hooks/toast";
import { useHistory } from "react-router-dom";

export const BCStep = ({ refreshSteps }) => {
  const { addToast } = useToast();
  const history = useHistory();
  const formSearchRef = useRef(null);
  const formBCTransferRef = useRef(null);
  const classes = useStyles();
  const [taxId, setTaxId] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [requirePassword, setRequirePassword] = useState(false);
  const [transferDescription, setTransferDescription] = useState("");
  const [transferValue, setTransferValue] = useState(0);
  const { title } = loadItem("Options");

  //VERIFYBALANCE
  const { balanceValue, handleGET: fetchAccountBalanceGET } =
    useAccountBalanceData();


  //FETCH TRANSACTION
  const {
    loading: transactionLoading,
    handlePOST: fetchTransaction,
    state: transactionState,
    success: transactionStatus,
  } = useTransferTransaction();

  //GET BC ACCOUNTS
  const {
    state: contactData,
    handleGET: fetchAccounts,
    loading: bcAccountLoading,
    success: isContactSuccess,
  } = useBrasilCashAccount();

  useEffect(() => {
    fetchAccountBalanceGET();
  }, []);

  //Workaround para redirecionar para a tela inicial qnd der algum erro na transferencia
  /* TODO: Implementar a requisição da api de maneira individual, para que o erro seja tratado
    em um block try/catch ao invés de utilizar um side-effect para tratativa de erro.
    Semelhante a maneira como esta fazendo transferencias via PIX
*/
  useEffect(() => {
    if (transactionLoading.POST === false && transactionStatus.POST === false) {
      history.push("/");
    }
  }, [transactionLoading, transactionStatus]);

  const getBCAccount = useCallback(async (data) => {
    try {
      const bollCPF_CNPJ = validateCPF_CNPJ(data.username);
      const schema = Yup.object().shape({
        username: Yup.string()
          .required("CPF/CNPJ obrigatório.")
          .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
      });
      await schema.validate(data, {
        abortEarly: false,
      });
    } catch (err) {
      const errors = getValidationErrors(err);
      formSearchRef.current?.setErrors(errors);
      return;
    }
    data.username = cleanTaxIDMask(data.username);
    fetchAccounts({ doc: data.username });
  }, []);

  const handleSubmitBCPay = useCallback(
    async (data) => {
      try {
        let convertedValue = convertToCents(data.value);
        data.value = stringToDecimal(data.value);

        const schema = Yup.object().shape({
          value: Yup.number()
            .typeError("Digite o valor do link")
            .required("Digite o valor do link")
            .moreThan(0, "Valor deve ser maior que R$ 0.00"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        setTransferValue(convertedValue);
        setTransferDescription(data.description);

        setRequirePassword(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formBCTransferRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [taxId, contactData]
  );

  const handleFinishTransaction = useCallback(
    async (pass) => {
      setRequirePassword(false);
      if (pass.length !== 4) return;
      let taxIDData = cleanTaxIDMask(taxId);

      // await api
      //   .put(`/pix/${pix_id}`, payload)
      //   .then((res) => {
      //     setConfirmPaymentState(res.data);
      //     setStep(3);
      //   })
      //   .catch((err) => {
      //     addToast({
      //       description: err.response.data.description,
      //       title: err.response.data.information,
      //       type: "error",
      //     });
      //   })
      //   .finally(() => {
      //     setLoadingPixConfirmation(false);
      //   });
      try {
        fetchTransaction({
          amount: transferValue,
          description: !!transferDescription ? transferDescription : null,
          target: {
            institution_code: "000",
            type: "internal",
            entity: {
              name: contactData?.name,
              document: taxIDData,
              document_type: taxIDData.length > 11 ? "cnpj" : "cpf",
            },
            validation: {
              password_combination: pass,
            },
          },
        });
      } catch (error) {
        history.push("/");
      }
    },
    [transferDescription, transferValue]
  );

  useEffect(() => {
    !!transactionState &&
      !transactionLoading.POST &&
      refreshSteps({
        step: 2,
        status: transactionStatus.POST,
        state: transactionState,
      });
  }, [transactionLoading, transactionState]);

  return (
    <div>
      <ModalLoader
        open={transactionLoading.POST || bcAccountLoading.GET}
        text={
          bcAccountLoading.GET
            ? "Carregando informações"
            : "Finanizando transferência"
        }
      />
      <div className={classes.header}>
        <Typography variant="h5">Transferência entre contas {title}</Typography>
        <p
          className={classes.backButton}
          onClick={() => refreshSteps({ step: 0 })}
        >
          <ArrowBack></ArrowBack>
          Voltar
        </p>
      </div>
      <Form ref={formSearchRef} onSubmit={getBCAccount}>
        <div className={classes.searchContact}>
          <div style={{ width: "50%" }}>
            <Input
              value={taxId}
              type="text"
              name="username"
              placeholder="CPF ou CNPJ"
              onChange={(e) => {
                setTaxId(handleTaxIDInput(e.currentTarget.value));
              }}
            />
          </div>
          <Button
            style={{ width: 50, textAlign: "center" }}
            title="buscar"
            type="submit"
            loading={bcAccountLoading.GET}
          >
            <FiSearch
              style={{
                verticalAlign: "middle",
                fontSize: 20,
              }}
            />
          </Button>
        </div>
      </Form>
      {contactData ? (
        <div className={classes.contactData}>
          <Typography
            variant="h6"
            style={{
              marginTop: 20,
              marginBottom: 10,
            }}
          >
            Transferir para:
          </Typography>
          <Form
            ref={formBCTransferRef}
            onSubmit={handleSubmitBCPay}
            className={classes.form}
          >
            <Input
              name="name"
              value={contactData?.name}
              type="text"
              placeholder="Nome"
              disabled
            />

            <Input
              value={linkValue}
              onChange={(e) => {
                setLinkValue(formatInputValue(e.currentTarget.value));
              }}
              name="value"
              type="text"
              maxLength={10}
              placeholder="Valor (R$)"
            />
            <Input
              name="description"
              type="text"
              placeholder="Descrição da transferência"
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ marginTop: 40 }}
                type="submit"
                loading={transactionLoading.POST}
              >
                Finalizar transferência
              </Button>
            </div>
          </Form>
          <PasswordConfirm
            submitPassword={(e) => handleFinishTransaction(e)}
            open={requirePassword}
            onClose={() => setRequirePassword(false)}
          />
        </div>
      ) : (
        <div className={classes.bcTransferPreSearch}>
          {isContactSuccess.GET !== null && isContactSuccess.GET === false ? (
            <>
              <p>Nenhuma referência encontrada, faça sua busca novamente.</p>
              <img src={searchImg}></img>
            </>
          ) : (
            <img src={paymentsImg}></img>
          )}
        </div>
      )}
    </div>
  );
};
