import React, { createContext, useContext, useEffect, useState } from "react";
import { loadItem } from "../Utils";
import useCallAxios from "../Hooks/useCallAxios";

export interface IStateReducerPin {
  isCreateRequiredPin: boolean;
  pin: string;
  subAccountId: string;
  handleSubAccountId: (val: string) => void;
  handleIsCreateRequiredPin: (val: boolean) => void;
  handlePin: (pin: string) => void;
}

const PinContext = createContext({} as IStateReducerPin);

const usePinContext = () => {
  const context = useContext(PinContext);

  return context;
};

const PinProvider = ({ children }) => {
  const storageIsCreateRequiredPin = loadItem("@PortalBC:isCreateRequiredPin");

  const [isCreateRequiredPin, handleIsCreateRequiredPin] = useState(
    storageIsCreateRequiredPin || false
  );
  const [pin, handlePin] = useState("");
  const [subAccountId, handleSubAccountId] = useState("");

  return (
    <PinContext.Provider
      value={{
        isCreateRequiredPin,
        pin,
        subAccountId,
        handleSubAccountId,
        handleIsCreateRequiredPin,
        handlePin,
      }}
    >
      {children}
    </PinContext.Provider>
  );
};

export { PinProvider, usePinContext };
