import styled, { css } from "styled-components";

interface ITabProps {
  selected: Boolean;
}

export const TabContainer = styled.div`
  margin: 25px 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
`;

export const TabButton = styled.button<ITabProps>`
  width: 140px;
  border: 0;
  color: #444;
  border-radius: 4px;
  height: 30px;
  transition: 0.3s;

  ${(props) =>
    props.selected &&
    css`
      color: white;
      background: ${props.theme.primary.light};
    `}
`;
