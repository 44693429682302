import Login from "../Pages/Public/Login";
import VerificationCode from "../Pages/Public/VerificationCode";
import ForgotPassword from "../Pages/Public/ForgotPassword";
import {
  Dashboard,
  Extract,
  PersonalCard,
  Receipts,
  Transfers,
  Payments,
  PixArea,
  Tickets,
  PixPayment,
  Account,
  Contacts,
  PixTransfer,
  PixSales,
  Operators,
} from "../Pages/Private";

import {
  Dashboard as DashboardIcon,
  FormatListBulleted as FormatListBulletedIcon,
  CreditCard as CreditCardIcon,
  Receipt as ReceiptIcon,
  Repeat as RepeatIcon,
  ReceiptOutlined as PaymentIcon,
  ViewWeek as ViewWeekIcon,
  Person as PersonIcon,
  Contacts as ContactsIcon,
  Pix as PixIcon,
  CancelOutlined as RefoundIcon,
  GroupAdd,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import { Refunds } from "../Pages/Private/Refunds";
import { PixDetails } from "../Components/Molecules/PixDetails";
import { Organizers } from "../Pages/Private/Organizers";
import ResetPassword from "../Pages/Public/ResetPassword";

export const defaultRoute = {
  path: "*",
  component: Dashboard,
  isHidden: true,
};

//PUBLIC ROUTES
export const loginRoute = { path: "/", label: "Login", component: Login };

export const tokenVerificationRoute = {
  path: "/step2",
  label: "Token",
  component: VerificationCode,
};

export const forgotPassword = {
  path: "/forgot",
  label: "ForgotPassword",
  component: ForgotPassword,
};

//PRIVATE ROUTES
export const dashboardRoute = {
  path: "/",
  label: "Início",
  component: Dashboard,
  icon: DashboardIcon,
};

export const extractRoute = {
  path: "/extrato",
  label: "Extrato",
  component: () => <Extract />,
  icon: FormatListBulletedIcon,
};

export const cardRoute = {
  path: "/meus-cartoes",
  label: "Meu cartão",
  component: () => <PersonalCard />,
  icon: CreditCardIcon,
};

export const pixRoute = {
  path: "/pix",
  label: "Área Pix",
  component: () => <PixArea />,
  icon: PixIcon,
};

export const receiptRoute = {
  path: "/comprovantes",
  label: "Comprovantes",
  component: () => <Receipts />,
  icon: ReceiptIcon,
};

export const organizersRoute = {
  path: "/pontos-de-venda",
  label: "Pontos de Venda",
  component: Organizers,
  icon: AttachMoneyIcon,
};

export const refoundsRoute = {
  path: "/estornos",
  label: "Estornos",
  component: () => <Refunds />,
  icon: RefoundIcon,
};

export const transferRoute = {
  path: "/transferencia",
  label: "Transferências",
  component: () => <Transfers />,
  icon: RepeatIcon,
};

export const paymentsRoute = {
  path: "/pagamento",
  label: "Pagamentos",
  component: () => <Payments />,
  icon: PaymentIcon,
};

export const ticketsRoute = {
  path: "/boletos",
  label: "Boletos",
  component: () => <Tickets />,
  icon: ViewWeekIcon,
};

export const pixTransferRoute = {
  path: "/pix/transferencia",
  label: "Transferência Pix",
  component: () => <PixTransfer />,
  // icon: ViewWeekIcon,
};

export const pixPaymentRoute = {
  path: "/pix/copia-cola",
  label: "Pix copia e cola",
  component: () => <PixPayment />,
  // icon: ViewWeekIcon,
};

export const pixSales = {
  path: "/pix/vendas",
  label: "Vender",
  component: () => <PixSales />,
  // icon: ViewWeekIcon,
};
export const pixDetails = {
  path: "/pix/detalhes",
  label: "Pix Detalhes",
  component: () => <PixDetails />,
};
// export const pixReceivement = {
//   path: "/pix/recebimento",
//   label: "Pagamento Pix",
//   component: () => <pixPayment />,
//   // icon: ViewWeekIcon,
// };
// export const pixTransfer = {
//   path: "/pix/transferencia",
//   label: "Pagamento Pix",
//   component: () => <PixPayment />,
//   // icon: ViewWeekIcon,
// };

// export const contactsRoute = {
//   path: "/contacts",
//   label: "Contatos",
//   component: () => <Contacts />,
//   icon: ContactsIcon,
// };

export const operatorsRoute = {
  path: "/operadores",
  label: "Operadores",
  component: () => <Operators />,
  icon: GroupAdd,
};

export const accountRoute = {
  path: "/perfil",
  label: "Meu Perfil",
  component: () => <Account />,
  icon: PersonIcon,
};

// export const retrievePassword = {
//   path: "/recuperar-senha",
//   label: "RetrievePassword",
//   component: RetrievePassword,
// };
export const resetPassword = {
  path: "/redefinir-senha",
  label: "RetrievePassword",
  component: ResetPassword,
};
