import { ProfileMenu } from "../ProfileMenu";
import {
  ButtonsContainer,
  Clock,
  CloseModalButton,
  Container,
  HeaderMainContent,
  LoadingContainer,
  LogoutButton,
  ModalContainer,
  RestoreSessionButton,
  TimerContainer,
  WarningSvg,
} from "./styles";
import { useSessionTimer } from "../../../Hooks/useSessionTimer";
import { CircularProgress, Dialog } from "@mui/material";
import { useEffect, useState } from "react";
import { Close, Warning } from "@mui/icons-material";
import { useRestoreSession } from "../../../Hooks/useRestoreSession";
import { useToast } from "../../../Hooks/toast";

export const HeaderContent = ({ logout, isDisabled, accountData }) => {
  const { addToast } = useToast();
  const [openModal, setOpenModal] = useState(false);
  const { getReturnValues } = useSessionTimer();
  const { handleRestoreSession, loading, success } = useRestoreSession();

  const [minutes, seconds] = getReturnValues();
  const mm =
    minutes < 10 && minutes + seconds >= 0
      ? "0" + minutes
      : minutes <= 0 && seconds <= 0
      ? "00"
      : minutes;
  const ss =
    seconds < 10 && minutes + seconds >= 0
      ? "0" + seconds
      : minutes <= 0 && seconds <= 0
      ? "00"
      : seconds;

  const onClose = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (minutes <= 0 && seconds < 0) {
      setOpenModal(true);
    }
  }, [minutes]);

  useEffect(() => {
    if (loading === false && success === true) {
      setOpenModal(false);
      addToast({
        title: "Sua sessão foi renovada!",
        description: "Sessão renovada com sucesso.",
        type: "info",
      });
    }
  }, [loading, success]);
  return (
    <>
      <Container>
        <HeaderMainContent>
          <TimerContainer>
            <div>
              <p>Sua sessão irá expirar em:</p>
            </div>
            <Clock>
              <p>{mm}m</p>:<p>{ss}s</p>
            </Clock>
          </TimerContainer>
          <ProfileMenu logout={logout} accountData={accountData} />
        </HeaderMainContent>
      </Container>
      <Dialog open={openModal} onClose={onClose}>
        <ModalContainer>
          <CloseModalButton onClick={onClose}>
            <span>Fechar</span>
            <Close />
          </CloseModalButton>
          <p>Seu tempo de sessão expirou!</p>
          <WarningSvg>
            <Warning />
          </WarningSvg>
          <ButtonsContainer>
            {loading ? (
              <LoadingContainer>
                <CircularProgress size={"1.5rem"} />
              </LoadingContainer>
            ) : (
              <RestoreSessionButton onClick={handleRestoreSession}>
                Renovar sessão
              </RestoreSessionButton>
            )}
            <LogoutButton onClick={logout}>Ok, sair</LogoutButton>
          </ButtonsContainer>
        </ModalContainer>
      </Dialog>
    </>
  );
};
