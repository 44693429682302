import React from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { Container, Content, Header } from "./styles";
import { Close } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const PrivacyPolicyModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <Container>
        <Header>
          <h1>POLÍTICA DE PRIVACIDADE</h1>
          <div style={{ position: "absolute", right: 0 }}>
            <IconButton title="Fechar" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </div>
        </Header>
        {/* <iframe
          src="https://bcpassets.blob.core.windows.net/docs/POLITICA-DE-PRIVACIDADE.html"
          title="W3Schools Free Online Web Tutorials"
        /> */}
        <div>
          <p>
            Esta Política de Privacidade (“Política”) se aplica a todos os
            serviços prestados pela{" "}
            <b>BRASIL CASH SOLUÇÕES EM PAGAMENTOS LTDA</b>
            ., inscrita no CNPJ sob o nº 30.507.541/0001-71, com sede na Alameda
            Rio Negro, 500, Sala 402, Torre 2, Condomínio West Towers –
            Barueri/SP, CEP 06454-000 (“
            <b>BRASIL CASH</b>”), em razão da utilização pelo USUÁRIO dos
            Serviços prestados pela
            <b>BRASIL CASH</b>.
          </p>
          <p>
            <br />
          </p>
          <p></p>
          <h2>
            A presente Política tem o objetivo de informar, de forma clara e
            completa, sobre como haverá o Tratamento dos Dados Pessoais do
            USUÁRIO, em decorrência da utilização dos Serviços prestados pela
            BRASIL CASH.
          </h2>
          <h2>
            A BRASIL CASH divulga sua Política para proteger a privacidade do
            USUÁRIO, garantindo que o Tratamento dos Dados Pessoais servirá
            apenas para possibilitar a prestação dos Serviços ou para outras
            finalidades previstas nesta Política.
          </h2>
          <h2>
            Ao utilizar os Serviços da BRASIL CASH, de acordo com as condições
            prevista no Contrato, o USUÁRIO concorda com esta Política e com o
            modo como se dará o Tratamento de seus Dados Pessoais pela BRASIL
            CASH. Caso o USUÁRIO não concorde com o Tratamento de seus Dados
            Pessoais, na forma prevista nesta Política, deverá se abster de
            realizar o Cadastro no Sistema de Pagamentos e de utilizar os
            Serviços da BRASIL CASH.
          </h2>
          <h2>
            Caso o USUÁRIO não queira divulgar os seus Dados Pessoais ou exija
            sua exclusão, o Cadastro no Sistema de Pagamentos poderá ser negado
            e/ou a prestação dos Serviços poderá ser limitada, visto que o uso
            dos Dados Pessoais é necessário para essas finalidades. Caso o
            USUÁRIO seja pessoa jurídica, algumas condições previstas nesta
            Política poderão não ser aplicáveis, nos termos da Legislação
            Aplicável, ou se aplicará às pessoas físicas responsáveis pela
            utilização direta do Sistema de Pagamentos.
          </h2>
          <h2>
            Para maiores informações sobre a utilização dos Serviços prestados
            pela BRASIL CASH, o USUÁRIO deve consultar o Contrato. A BRASIL CASH
            poderá alterar as condições desta Política periodicamente, sendo que
            a versão atualizada poderá ser consultada a qualquer momento pelo
            USUÁRIO na Plataforma ou outro meio disponível pela BRASIL CASH.
          </h2>
          <p>
            <br />
          </p>
          <ol id="l1">
            <li>
              <h2>Definições</h2>
              <p>
                <br />
              </p>
              <ol id="l2">
                <li>
                  <p>
                    Sem prejuízo de outras definições constantes nesta Política
                    ou no Contrato, as palavras e expressões utilizadas pela
                    primeira letra maiúscula, terão as seguintes definições:
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Cadastro”:
                    <span>
                      dados pessoais e demais informações solicitadas pelas{" "}
                    </span>
                    BRASIL CASH
                    <span>
                      , necessárias para o credenciamento e manutenção do{" "}
                    </span>
                    USUÁRIO <span>no Sistema de Pagamentos.</span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Contrato”:
                    <span>
                      instrumento ou instrumentos que regula(m) as regras, as
                      condições e os limites dos Serviços prestados pela{" "}
                    </span>
                    BRASIL CASH <span>ao </span>USUÁRIO
                    <span>
                      em razão da utilização do Sistema de Pagamentos.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Dados Anonimizados”:
                    <span>
                      Dados Pessoais que, isoladamente ou em conjunto com os
                      Dados de Uso Técnico, Informações do Dispositivo e/ou
                      Localização Geográfica, não permitem a identificação do{" "}
                    </span>
                    USUÁRIO
                    <span>
                      , considerando a utilização de meios técnicos disponíveis
                      na ocasião de seu Tratamento.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Dados de Uso Técnico”:
                    <span>informações que a </span>BRASIL CASH
                    <span>
                      tratar em razão da utilização de dispositivo celular,
                      computador ou outro dispositivo que o{" "}
                    </span>
                    USUÁRIO
                    <span>
                      utilizar para acessar a Plataforma e utiliza e o Sistema
                      de Pagamentos. Os Dados de Uso Técnico mostram como o{" "}
                    </span>
                    USUÁRIO
                    <span>utiliza os serviços prestados pela </span>
                    BRASIL CASH
                    <span>
                      , incluindo o endereço IP (Protocolo da Internet),
                      estatísticas sobre como as páginas são carregadas ou
                      visualizadas, os sites que o{" "}
                    </span>
                    USUÁRIO
                    <span>
                      visitou e informações de navegação coletadas por meio de
                      Cookies ou tecnologia semelhante.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <p>
                    “<b>Dados Pessoais”: </b>informações pessoais associadas ao
                    <b>USUÁRIO </b>como uma pessoa física identificada ou
                    identificável. Podem incluir nome de pessoa física, firma ou
                    denominação social de pessoa jurídica a que esteja
                    relacionado, autorretrato, endereço, número de telefone,
                    e-mail, nome e número da instituição financeira ou de
                    pagamento, número de agência, número da conta corrente,
                    poupança ou de pagamento (“Conta”), data de nascimento, nome
                    completo da mãe, número ou cópia de documentos oficiais (por
                    exemplo, RG, CNH, CPF, dentre outros). Os
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    Dados de Uso Técnico e as Informações do Dispositivos serão
                    considerados Dados Pessoais quando utilizadas para
                    individualizar o<b>USUÁRIO </b>ou sempre que seja possível
                    sua identificação.
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Informações do Dispositivo”:
                    <span>
                      dados que podem ser coletados automaticamente de qualquer
                      dispositivo utilizado para acessar a Plataforma. Essas
                      informações podem incluir, mas não se limitam ao tipo de
                      dispositivo, conexões de rede do dispositivo, nome do
                      dispositivo, endereço IP do dispositivo, informações sobre
                      o navegador do dispositivo e a conexão de internet usada
                      para acessar a Plataforma.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Legislação Aplicável”:
                    <span>
                      toda a legislação em vigor, aplicável sobre segurança da
                      informação, privacidade e proteção de dados, incluindo,
                      mas não se limitando, a Lei nº 13.709/2018 - Lei Geral de
                      Proteção de Dados Pessoais, Lei nº 12.965/2014 - Marco
                      Civil da Internet, Lei nº 8.078/1990 - Código de Defesa do
                      Consumidor, Lei Complementar nº 166/2019 - Lei do Cadastro
                      Positivo, Lei nº 12.527/2011 - Lei de Acesso à Informação,
                      Decreto nº 7.962/2013 - Decreto Comércio Eletrônico e
                      outras leis e normas aplicáveis ao Sistema de Pagamentos.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Localização Geográfica”:
                    <span>informações que identificam a localização do </span>
                    USUÁRIO
                    <span>
                      mediante, por exemplo, coordenadas de latitude e longitude
                      obtidas por GPS, Wi-Fi ou triangulação de localização
                      celular. A Plataforma pode solicitar permissão para
                      compartilhar a localização atual do{" "}
                    </span>
                    USUÁRIO<span>. Se o </span>USUÁRIO
                    <span>
                      não concordar com a coleta das informações de Localização
                      Geográfica, o Sistema de Pagamentos pode não funcionar
                      adequadamente.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Prestadores de Serviço”:
                    <span>
                      prestadores de serviços, cujo sistema se encontra
                      integrado com o Sistema de Pagamentos, para possibilitar a
                      execução de determinados Serviços.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Serviços”: <span>serviços prestados pela </span>
                    BRASIL CASH <span>ao </span>USUÁRIO,
                    <span>
                      de acordo com as condições previstas no Contrato.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Sistema de Pagamentos”:
                    <span>tecnologia disponibilizada pela </span>
                    BRASIL CASH
                    <span>
                      , e que se encontra integrada com o sistema dos
                      Prestadores de Serviço, para possibilitar a prestação dos
                      Serviços ao{" "}
                    </span>
                    USUÁRIO<span>.</span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Transação”: <span>operação em que o </span>
                    USUÁRIO
                    <span>
                      realiza ou recebe pagamentos por meio dos instrumentos de
                      pagamento disponíveis no Sistema de Pagamentos.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “Tratamento”:
                    <span>
                      toda operação realizada com os Dados Pessoais do{" "}
                    </span>
                    USUÁRIO
                    <span>
                      , em razão da coleta, produção, recepção, classificação,
                      utilização, acesso, reprodução, transmissão, distribuição,
                      processamento, arquivamento, armazenamento, eliminação,
                      avaliação ou controle da informação, modificação,
                      comunicação, transferência, difusão ou extração.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    “USUÁRIO”:
                    <span>
                      pessoa jurídica ou pessoa física (incluindo
                      representantes, mandatários ou prepostos autorizados para
                      executar as Transações) que fornece seus Dados Pessoais
                      para Tratamento pela{" "}
                    </span>
                    BRASIL CASH
                    <span>
                      , em razão dos Serviços prestados por meio do Sistema de
                      Pagamentos.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Obtenção dos Dados Pessoais</h2>
              <p>
                <br />
              </p>
              <ol id="l3">
                <li>
                  <p>
                    A <b>BRASIL CASH </b>realiza o Tratamento dos Dados Pessoais
                    mínimos necessários para a utilização, pelo USUÁRIO, do
                    conjunto de Serviços prestados por meio do Sistema de
                    Pagamentos, para, de acordo com o Contrato, possibilitar
                    o(a):
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l4">
                    <li>
                      <p>
                        Abertura de uma conta de pagamento (“Conta de
                        Pagamento”) e a realização de Transações;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Recebimento de recursos em razão do pagamento com cartão
                        de crédito ou débito (“Cartão”) por meio dos arranjos de
                        pagamento instituídos pelas bandeiras aceitas no Sistema
                        de Pagamentos; e/ou
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Realização de pagamentos instantâneos no âmbito do PIX,
                        se disponível, de acordo com as regras instituídas pelo
                        Bacen (“Pagamentos Instantâneos”); e/ou
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Emissão de cartão pré-pago (“Cartão Pré-Pago”) por uma
                        Emissora.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Para o credenciamento ao Sistema de Pagamentos, o
                    <b>USUÁRIO </b>
                    deverá disponibilizar os Dados Pessoais solicitados no
                    Cadastro, para que o <b>USUÁRIO </b>possa ser devidamente
                    identificado. O Cadastro solicita apenas os dados
                    necessários para a prestação dos Serviços pela{" "}
                    <b>BRASIL CASH</b>.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l5">
                    <li>
                      <p>
                        Com a finalidade de prevenir fraudes e garantir a
                        autenticidade dos Dados Pessoais fornecidos, poderão ser
                        solicitadas outras informações não contidas no Cadastro,
                        bem como o envio de fotos ou cópia de documentos que
                        permitam a confirmação dos dados fornecidos pelo{" "}
                        <b>USUÁRIO</b>. Neste caso, o<b>USUÁRIO </b>será
                        contatado diretamente. Essas informações e documentos
                        adicionais poderão ser armazenados pela
                        <b>BRASIL CASH </b>pelo tempo necessário para garantia
                        de integridade e confiabilidade do uso de seus Serviços
                        pelo
                        <b>USUÁRIO</b>.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Ainda, a <b>BRASIL CASH </b>poderá consultar as
                        informações disponíveis em bancos de dados públicos ou
                        privados, inclusive bureaus de crédito.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        A <b>BRASIL CASH </b>também solicitará os dados
                        financeiros do
                        <b>USUÁRIO </b>que forem necessários para a prestação
                        dos Serviços, e que podem incluir, conforme aplicável:
                        (i) os dados de identificação de Conta de titularidade
                        do <b>USUÁRIO</b>; (ii) as informações sobre a
                        Transação; e (iii) a outras informações associadas à
                        Transação, como a origem dos recursos e os motivos para
                        sua realização.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Caso o <b>USUÁRIO </b>seja indicado por um parceiro
                    comercial da
                    <b>BRASIL CASH</b>, seus Dados Pessoais poderão ser
                    compartilhados diretamente pelo parceiro, com a finalidade
                    de facilitar o Cadastro no Sistema de Pagamentos. O
                    compartilhamento dos Dados Pessoais se dará mediante
                    consentimento prévio e expresso do <b>USUÁRIO</b>, de acordo
                    com a Legislação Aplicável.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    No Tratamento de Dados Pessoais serão observadas, de acordo
                    com a Legislação Aplicável, os princípios estritos da
                    finalidade, adequação, necessidade, livre acesso, qualidade
                    dos dados, transparência, segurança, prevenção, não
                    discriminação, responsabilização e prestação de contas.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l6">
                    <li>
                      <p>
                        Antes de concluída a Transação solicitada pelo{" "}
                        <b>USUÁRIO </b>
                        no Sistema de Pagamentos, a <b>BRASIL CASH </b>poderá
                        solicitar documentos e informações adicionais que se
                        façam necessários para a identificação e prevenção de
                        situações de fraude, lavagem de dinheiro ou destinação
                        irregular dos recursos. Essas informações e documentos
                        adicionais poderão ser armazenados pela
                        <b>BRASIL CASH </b>pelo tempo necessário para garantia
                        do cumprimento da legislação e da política de prevenção
                        a fraudes e lavagem de dinheiro da <b>BRASIL CASH</b>.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Os Dados Pessoais podem incluir a condição financeira do
                        <b>USUÁRIO</b>, que serão coletados, armazenados e
                        compartilhados para a verificação da pontuação de
                        crédito (credit score) e o acompanhamento da situação da
                        inscrição no CPF ou CNPJ.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    A Plataforma da <b>BRASIL CASH </b>pode utilizar Informações
                    do Dispositivo, Dados de Uso Técnico e Localização
                    Geográfica do
                    <b>USUÁRIO</b>.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l7">
                    <li>
                      <p>
                        Ainda, a Plataforma da <b>BRASIL CASH </b>pode utilizar
                        “Cookies” (arquivos gravados em seu dispositivo para
                        obter informação de navegação dentro do website), para
                        fim de confirmação de identidade e análise do
                        comportamento de navegação, de modo a melhorar a
                        segurança e identificar problemas e dificuldades de
                        utilização da Plataforma. Caso o<b>USUÁRIO </b>não
                        concorde com sua utilização, poderá desabilitar o uso
                        dessa função nas opções de seu browser para recusar o
                        recebimento de Cookies e removê-los a qualquer momento.
                        O USUÁRIO deverá verificar as opções e ferramentas
                        disponíveis no software utilizado.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Utilização dos Dados Pessoais</h2>
              <p>
                <br />
              </p>
              <ol id="l8">
                <li>
                  <p>
                    A <b>BRASIL CASH </b>realizará o Tratamento dos Dados
                    Pessoais, Dados de Uso Técnico, Informações do Dispositivo
                    ou Localização Geográfica do <b>USUÁRIO</b>, para operar o
                    Sistema de Pagamentos e prestar os Serviços, incluindo, mas
                    não se limitando, as seguintes situações:
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l9">
                    <li>
                      <p>
                        Realizar o Cadastro e autenticar o acesso do{" "}
                        <b>USUÁRIO </b>na Plataforma;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Comunicar-se com o <b>USUÁRIO </b>sobre o Cadastro,
                        utilização do Sistema de Pagamentos ou prestação dos
                        Serviços;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Enviar ou solicitar pagamentos, em razão das Transações
                        realizadas pelo <b>USUÁRIO </b>no Sistema de Pagamentos;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizar a verificação de crédito e da reputação
                        financeira do
                        <b>USUÁRIO</b>;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Manter os Dados Pessoais do <b>USUÁRIO </b>atualizados;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizar a verificação da identidade do <b>USUÁRIO </b>
                        para gerenciar riscos e proteger o Sistema de Pagamentos
                        contra fraudes;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Classificar o <b>USUÁRIO</b>, bem como monitorar e
                        analisar seu comportamento na utilização do Sistema de
                        Pagamento, com a finalidade de prevenir fraudes e atos
                        ilícitos, inclusive quando se tratar de pessoa
                        politicamente exposta;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Criar a conexão do <b>USUÁRIO </b>com o sistema dos
                        Prestadores de Serviço;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizar e promover campanhas de marketing e
                        aprimoramento dos Serviços ou da experiência na
                        utilização do Sistema de Pagamentos;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Oferecer serviços personalizados prestados por
                        terceiros, inclusive mediante a utilização de Cookies;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Promover ofertas de produtos ou serviços específicos -
                        se o<b>USUÁRIO </b>optar por compartilhar seus Dados
                        Pessoais- mediante a disponibilização de anúncios,
                        resultados de pesquisas e outros conteúdos
                        personalizados; e
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Dar cumprimento às obrigações previstas no Contrato, na
                        Legislação Aplicável e ou de leis e normas decorrentes
                        dos órgãos reguladores.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                  <ol id="l10">
                    <li>
                      <p>
                        A <b>BRASIL CASH </b>poderá coletar, armazenar e
                        compartilhar os Dados Pessoais do
                      </p>
                      <h2>
                        USUÁRIO
                        <span>
                          sempre que houver legítimo interesse da BRASIL CASH
                          nessa utilização ou
                        </span>
                      </h2>
                      <p>
                        <br />
                      </p>
                      <p>
                        quando for necessário para a execução dos Serviços,
                        cumprimento de deveres legais ou regulatórios impostos à
                        BRASIL CASH ou exercício e defesa de direitos da{" "}
                        <b>BRASIL CASH </b>ou de terceiros.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        A <b>BRASIL CASH </b>poderá compartilhar os Dados
                        Pessoais com empresas do grupo da <b>BRASIL CASH </b>
                        e/ou Prestadores de Serviço que integram o Sistema de
                        Pagamentos, quando se fizer necessário para a execução
                        dos Serviços contratados.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Os Dados Pessoais do <b>USUÁRIO </b>serão compartilhados
                    pela
                    <b>BRASIL CASH </b>com outros <b>USUÁRIOS </b>e Prestadores
                    de Serviço, por rede segura, limitado somente ao que for
                    necessário para identificar o <b>USUÁRIO </b>e a Transação
                    realizada por meio do Sistema de Pagamentos.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Os Dados Pessoais do <b>USUÁRIO </b>também serão
                    compartilhados pela
                    <b>BRASIL CASH </b>com terceiros contratados para prover
                    serviços de computação, transferência de dados e hospedagem
                    em nuvem, serviços de proteção ao crédito, ferramentas de
                    análise de fraude e ferramentas de análise de prevenção à
                    lavagem de dinheiro; contanto que esses terceiros guardem o
                    mesmo padrão de privacidade e segurança aplicados pela{" "}
                    <b>BRASIL CASH </b>e estejam contratualmente obrigados a não
                    acessar o conteúdo ou compartilhar os Dados Pessoais, exceto
                    mediante ordem expressa da
                    <b>BRASIL CASH.</b>
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l11">
                    <li>
                      <p>
                        Os Dados Pessoais serão coletados no Brasil e podem ser
                        transferidos para outro país, no qual a empresa
                        responsável pela hospedagem se encontra sediada e/ou
                        mantém seus servidores. Neste caso, a{" "}
                        <b>BRASIL CASH </b>
                        irá garantir que o destinatário estrangeiro proporciona
                        o grau de proteção de Dados Pessoais exigido nesta
                        Política, em consonância com a Legislação Aplicável.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Os Dados Pessoais do <b>USUÁRIO </b>e os dados das
                    Transações realizadas no Sistema de Pagamentos poderão ser
                    utilizados pela
                    <b>BRASIL CASH </b>para elaboração de pesquisas e
                    estatísticas voltadas a analisar eficiência do Sistema de
                    Pagamentos, número de
                    <b>USUÁRIOS</b>, o valor das Transações realizadas no
                    Sistema de Pagamentos, entre outros; desde que tais
                  </p>
                  <p>
                    <br />
                  </p>
                  <p>
                    informações sejam convertidas em Dados Anonimizados ou na
                    forma de valores totais para a criação de estatísticas, de
                    forma a preservar a individualidade e identificação do{" "}
                    <b>USUÁRIO</b>.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Ressalvado o disposto nesta Política, e salvo previsão em
                    contrário na Legislação Aplicável, a <b>BRASIL CASH </b>não
                    divulgará nem compartilhará Dados Pessoais do{" "}
                    <b>USUÁRIO </b>
                    com terceiros.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Transações com Cartão</h2>
              <p>
                <br />
              </p>
              <ol id="l12">
                <li>
                  <p>
                    Os dados referentes ao Cartão serão coletados apenas no
                    momento da utilização do Sistema de Pagamentos, por meio de
                    equipamentos ou sistemas próprios para captura das
                    Transações.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l13">
                    <li>
                      <p>
                        O Tratamento dos dados do Cartão será realizado apenas
                        para possibilitar a realização da Transação perante as
                        credenciadoras, emissores, bandeiras, processadoras e
                        outros Prestadores de Serviço integrantes do Sistema de
                        Pagamentos. Estes dados não serão armazenados pela{" "}
                        <b>BRASIL CASH</b>, de acordo com as regras instituídas
                        pelo PCI.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Para fins desta Política, entende-se por “PCI”
                        (abreviação de Payment Card Industry): programa de
                        gerenciamento de riscos patrocinado pelas bandeiras
                        instituidoras dos arranjos de pagamento de Cartões, de
                        alcance geral e vinculação do
                        <b>USUÁRIO</b>, da <b>BRASIL CASH </b>
                        <a
                          href="http://www.pcisecuritystandards.org/"
                          target="_blank"
                        >
                          e dos Prestadores de Serviço; desenvolvido com o
                          objetivo de estipular padrões mínimos para proteção
                          dos Dados Pessoais, dados das Transações e dados do
                          Cartão. É baseado nas normativas definidas pelo PCI
                          Council e tem como função determinar os padrões e
                          regras de segurança da informação para todos os
                          integrantes do mercado de Cartões, conforme divulgado
                          em{" "}
                        </a>
                        <a
                          href="http://www.pcisecuritystandards.org/"
                          target="_blank"
                        >
                          www.pcisecuritystandards.org.
                        </a>
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        O padrão de segurança instituído pelo PCI foi
                        desenvolvido para incentivar e aprimorar a segurança dos
                        dados do titular do Cartão e de todas as Transações
                        realizadas no âmbito dos arranjos das bandeiras.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        O PCI compreende um conjunto mínimo de requisitos para
                        proteger os dados do Cartão e pode ser aperfeiçoado por
                        controles e práticas adicionais para amenizar ainda mais
                        os riscos de sua utilização. Além disso, os requisitos
                        legais ou regulatórios podem exigir proteção específica
                        para Dados Pessoais, de modo que o PCI é aplicado de
                        modo complementar, e não substitui a Legislação
                        Aplicável.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Os dados do Cartão são capturados no Sistema de Pagamentos,
                    de modo criptografado, dentro dos padrões de segurança
                    instituídos pelo PCI. A coleta se dará por uma processadora,
                    que as transmitirá para as credenciadoras e, por
                    conseguinte, para os emissores e bandeiras. A
                    <b>BRASIL CASH </b>não terá acesso a tais informações,
                    eximindo-se de toda e qualquer responsabilidade com relação
                    a coleta e transmissão dos dados do Cartão.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Os Prestadores de Serviço são responsáveis pela observância
                    e aplicação das regras e requisitos do PCI, que se aplica a
                    todas as entidades envolvidas no processo de realização da
                    Transação com Cartão.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Pagamentos Instantâneos pelo PIX</h2>
              <p>
                <br />
              </p>
              <ol id="l14">
                <li>
                  <p>
                    Para a realização de Transações pelo PIX, se disponível, o
                    <b>USUÁRIO </b>poderá solicitar o registro de uma Chave Pix
                    vinculada à sua Conta de Pagamento ou Conta, sendo
                    necessário que o USUÁRIO tenha a posse da Chave Pix
                    escolhida – com exceção da chave aleatória – e que dê o seu
                    consentimento para tal registro.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l15">
                    <li>
                      <p>
                        Ao registrar uma Chave Pix, o <b>USUÁRIO </b>declara ter
                        conhecimento de que terceiros com quem o <b>USUÁRIO </b>
                        venha a realizar Transações por meio do PIX, terão
                        visibilidade, a cada Transação, de seu nome, dados de
                        identificação da Chave Pix, e do nome da{" "}
                        <b>BRASIL CASH </b>e dos Prestadores de Serviço
                        envolvidos.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <h2>Para fins desta Política entende-se por:</h2>
                      <p>
                        <br />
                      </p>
                      <h2>
                        Chave Pix”:
                        <span>informação indicada pelo </span>USUÁRIO
                        <span>
                          para identificar sua Conta no âmbito do arranjo PIX,
                          por meio de: (i) número do CPF ou CNPJ (conforme
                          aplicável); (ii) número do
                        </span>
                      </h2>
                      <p>
                        <br />
                      </p>
                      <p>
                        telefone celular; (iii) endereço de e-mail; ou (iv)
                        chave aleatória (sequência de letras e números gerados
                        aleatoriamente pelo Bacen), de livre escolha do{" "}
                        <b>USUÁRIO</b>. A utilização da Chave Pix permite obter
                        as informações sobre os usuários pagadores e recebedores
                        armazenadas no Diretório de Identificadores de Contas
                        Transacionais (“DICT”) gerido pelo Bacen, com a
                        finalidade de facilitar o processo de iniciação das
                        Transações de Pagamentos Instantâneos e de mitigar o
                        risco de fraude no âmbito do PIX.
                      </p>
                      <p>
                        <br />
                      </p>
                      <h2>
                        “PIX”:
                        <span>
                          arranjo de pagamento que disciplina a prestação de
                          serviços relacionados com as Transações de Pagamentos
                          Instantâneos, cujas regras e condições são instituídas
                          pelo Bacen.
                        </span>
                      </h2>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    O <b>USUÁRIO </b>declara-se ciente de que, nos termos do
                    regulamento do PIX e demais normas instituídas pelo Banco
                    Central do Brasil (“Bacen”), a <b>BRASIL CASH </b>será
                    responsável pela realização das Transações de Pagamentos
                    Instantâneos, mediante a transmissão dos Dados Pessoais e da
                    Transação ao Prestador de Serviço responsável pela
                    liquidação no âmbito do PIX.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l16">
                    <li>
                      <p>
                        Para a realização das Transações de Pagamentos
                        Instantâneos, o<b>USUÁRIO </b>manifesta seu expresso
                        consentimento, para coleta, tratamento e transmissão de
                        seus Dados Pessoais ao Prestador de Serviço, que, na
                        qualidade de participante direto, será responsável pela
                        liquidação das Transações perante o PIX.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    O <b>USUÁRIO </b>também autoriza a <b>BRASIL CASH </b>a
                    realizar a coleta, Tratamento e transmissão, ao Prestador de
                    Serviço com acesso ao DICT, para realizar o Cadastro,
                    exclusão e reinvindicação das Chaves Pix.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l17">
                    <li>
                      <p>
                        A realização, pelo <b>USUÁRIO</b>, do cadastro para
                        registro de sua Chave Pix implica na manifestação prévia
                        de seu consentimento para o registro das Chaves Pix no
                        DICT. Tal manifestação será confirmada e o registro da
                        Chave Pix será realizado no DICT, caso o <b>USUÁRIO </b>
                        não exclua sua Chave Pix no Sistema de Pagamentos da{" "}
                        <b>BRASIL CASH</b>.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Emissão de Cartão Pré-Pago</h2>
              <p>
                <br />
              </p>
              <ol id="l18">
                <li>
                  <p>
                    O <b>USUÁRIO </b>poderá, sempre disponível e de acordo com
                    os termos do Contrato, solicitar um Cartão Pré-Pago que será
                    emitido por uma emissora parceira da <b>BRASIL CASH </b>
                    (“Emissora”).
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Ao solicitar a emissão de Cartão Pré-Pago, o<b>USUÁRIO </b>
                    expressamente autoriza que a<b>BRASIL CASH </b>compartilhe
                    seus Dados Pessoais com a Emissora, bem como declara-se
                    ciente e concorda que a <b>BRASIL CASH </b>terá acesso a
                    todos os dados financeiros decorrentes das Transações
                    realizadas com o Cartão Pré-Pago.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    A Emissora realizará o Tratamento dos Dados Pessoais do
                    <b>USUÁRIO </b>e poderá:
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l19">
                    <li>
                      <p>
                        Adotar procedimentos para assegurar a devida diligência
                        na identificação, qualificação e classificação do{" "}
                        <b>USUÁRIO</b>;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Realizar a verificação da condição do <b>USUÁRIO </b>de
                        pessoa exposta politicamente;
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Coletar informações sobre a renda e/ou o faturamento do
                        <b>USUÁRIO</b>; e
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Monitorar as Transações realizadas pelo <b>USUÁRIO </b>
                        com o objetivo de identificar suspeitas de lavagem de
                        dinheiro e/ou de financiamento ao terrorismo.
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                  <ol id="l20">
                    <li>
                      <p>
                        A Emissora poderá solicitar informações e documentos
                        adicionais, além daqueles informados no Cadastro, para
                        possibilitar a emissão do Cartão Pré-Pago.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <p>Comunicação</p>
              <p>
                <br />
              </p>
              <ol id="l21">
                <li>
                  <p>
                    O e-mail do <b>USUÁRIO</b>, informado no preenchimento do
                    Cadastro, será utilizado como meio de comunicação pela{" "}
                    <b>BRASIL CASH</b>, apenas para a solicitação de documentos
                    e de Dados Pessoais, assim como para comunicação sobre os
                    Serviços prestados.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    O <b>USUÁRIO </b>poderá, a qualquer momento, optar por não
                    receber newsletter, materiais promocionais e de marketing
                    mediante solicitação expressa à <b>BRASIL CASH</b>,
                    mantendo-se, nesse caso, apenas o envio de mensagens
                    relacionadas à execução dos Serviços contratados.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    A <b>BRASIL CASH </b>não utiliza serviços de terceiros para
                    enviar e-mails em seu nome. Se o <b>USUÁRIO </b>receber
                    e-mail que acredita não ter sido enviado pela{" "}
                    <b>BRASIL CASH</b>, deverá se abster de adotar qualquer ação
                    e entrar em contato imediatamente com a<b>BRASIL CASH </b>
                    para confirmar sua veracidade.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    O <b>USUÁRIO </b>declara-se ciente de que, para verificar a
                    realização de qualquer Transação no Sistema de Pagamentos,
                    deverá acessar a Plataforma da <b>BRASIL CASH</b>; não
                    servindo como comprovação o mero recebimento de qualquer
                    comunicação por outros meios (incluindo e-mail, WhatsApp,
                    telefone e SMS).
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Armazenamento</h2>
              <p>
                <br />
              </p>
              <ol id="l22">
                <li>
                  <p>
                    Os Dados Pessoais coletados pela <b>BRASIL CASH </b>são
                    armazenados em servidores seguros, de forma criptografada,
                    com a utilização de medidas de segurança da informação
                    constantemente atualizadas. Os Dados Pessoais serão mantidos
                    confidenciais e serão adotadas todas as medidas possíveis
                    contra perda, roubo, uso indevido, alteração e acesso não
                    autorizado.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Os Dados Pessoais relacionados ao Cadastro e realização de
                    Transações no Sistema de Pagamentos serão armazenados
                    enquanto o<b>USUÁRIO </b>mantiver um Cadastro ativo e
                    utilizar os Serviços da
                    <b>BRASIL CASH</b>, pelo tempo que for necessário para
                    atingir as finalidades relacionadas com os Serviços,
                    inclusive para fins de cumprimento de quaisquer obrigações
                    legais, regulatórias, contratuais, de prestação de contas ou
                    requisição por autoridades competentes.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l23">
                    <li>
                      <p>
                        Os Dados Pessoais serão armazenados pelo prazo mínimo de
                        05 (cinco) anos contados do encerramento do Contrato, ou
                        outro prazo que vier a ser determinado na Legislação
                        Aplicável.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    A <b>BRASIL CASH </b>emprega padrões de segurança avançados
                    com a finalidade de garantir a proteção dos Dados Pessoais e
                    de fornecer um ambiente seguro para a realização de
                    Transações, mediante a adoção de práticas relativas à
                    segurança da informação, como autenticação dos Usuários,
                    rígido controle de acesso, criptografia dos Dados Pessoais e
                    do conteúdo das Transações, prevenção e detecção de intrusão
                    e acessos não autorizados, prevenção de vazamento de
                    informações, realização periódica de testes e varreduras
                    para detecção de vulnerabilidades, proteção contra softwares
                    maliciosos, mecanismos de rastreabilidade, controles de
                    acesso e de segmentação da rede de computadores, manutenção
                    de cópias de segurança dos Dados Pessoais, entre outros.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l24">
                    <li>
                      <p>
                        Apesar de a <b>BRASIL CASH </b>se dedicar a proteger o
                        Sistema de Pagamentos, o USUÁRIO é responsável por
                        proteger e manter a confidencialidade de seus Dados
                        Pessoais.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        A BRASIL CASH não se responsabiliza por Dados Pessoais
                        que o<b>USUÁRIO </b>compartilhar com terceiros. Assim, é
                        importante que o <b>USUÁRIO </b>adote um comportamento
                        seguro, identificando e evitando situações que possam
                        ameaçar a segurança dos seus Dados Pessoais.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Caso o <b>USUÁRIO </b>venha a utilizar seus Dados
                        Pessoais em sites ou serviços de terceiros, a
                        responsabilidade de proteção e armazenamento dos Dados
                        Pessoais será dos provedores de tais serviços; cabendo
                        ao <b>USUÁRIO </b>se atentar ao conteúdo das políticas
                        de privacidade aplicáveis ao uso dos sites ou serviços
                        de terceiros.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
                <li>
                  <p>
                    Na medida da Legislação Aplicável, a <b>BRASIL CASH </b>não
                    se responsabiliza por violações ilegais de seu Sistema de
                    Pagamentos, que venham a comprometer a sua base de dados e
                    os Dados Pessoais do USUÁRIOS, bem como não se
                    responsabiliza pela utilização indevida dos Dados Pessoais
                    obtidos de forma fraudulenta ou ilícita.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Em caso de suspeita ou confirmação de violação do Sistema de
                    Pagamentos ou de perda de Dados Pessoais do <b>USUÁRIO</b>,
                    a<b>BRASIL CASH </b>envidará seus melhores esforços e tomará
                    medidas imediatas para eliminar ou reduzir os riscos de
                    danos ao
                    <b>USUÁRIO</b>, e informará os
                  </p>
                  <p>
                    <br />
                  </p>
                  <h2>
                    USUÁRIOS
                    <span>
                      potencialmente afetados e às autoridades competentes de
                      tal fato, os riscos envolvidos e as medidas necessárias
                      para evitar tais danos.
                    </span>
                  </h2>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Se o Contrato for encerrado pelo <b>USUÁRIO, </b>a
                    <b>BRASIL CASH </b>poderá utilizar e divulgar os Dados
                    Pessoais do USUÁRIO de acordo com essa Política.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Direitos do USUÁRIO</h2>
              <p>
                <br />
              </p>
              <ol id="l25">
                <li>
                  <p>
                    É permitido ao <b>USUÁRIO</b>, a qualquer tempo, nos limites
                    da Legislação Aplicável, exercer os seguintes direitos sobre
                    seus Dados Pessoais (“Direitos”):
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l26">
                    <li>
                      <p>
                        Direito de confirmação de existência de tratamento de
                        seus Dados Pessoais.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>Direito de acesso aos Dados Pessoais tratados.</p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Direito de correção de eventuais Dados Pessoais
                        incompletos, inexatos ou desatualizados.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Direito ao bloqueio, eliminação ou anonimização de Dados
                        Pessoais desnecessários, excessivos ou tratados em
                        desconformidade com a legislação, assim como a oposição
                        ao tratamento dos Dados Pessoais nessas mesmas
                        situações.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Direito à portabilidade dos seus Dados Pessoais a outra
                        empresa, na medida em que seja exigida por regulamentos
                        oficiais sobre o assunto.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Direito de obter informação das entidades públicas e
                        privadas com as quais houve uso compartilhado de seus
                        dados.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Direito ser informado sobre a possibilidade de não
                        fornecer seu consentimento e sobre as consequências da
                        negativa, nos casos em que seus Dados Pessoais forem
                        coletados e tratados mediante consentimento, assim como
                        o direito à eliminação, quando requerida, dos Dados
                      </p>
                    </li>
                  </ol>
                  <p>
                    <br />
                  </p>
                  <p>
                    Pessoais coletados mediante seu consentimento, na forma da
                    legislação aplicável, e direito de revogação do seu
                    consentimento para coleta e tratamento de dados nestes
                    mesmos casos.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    O <b>USUÁRIO </b>poderá, a qualquer tempo, exercer os
                    Direitos assegurados nesta Política ou previstos na
                    Legislação Aplicável, mediante solicitação expressa à{" "}
                    <b>BRASIL CASH</b>, por meio dos canais de atendimento
                    indicados nesta Política.
                  </p>
                  <p>
                    <br />
                  </p>
                  <ol id="l27">
                    <li>
                      <p>
                        O requerimento do <b>USUÁRIO </b>deverá ser solicitado
                        por escrito e acompanhado de prova de identidade. A
                        <b>BRASIL CASH </b>poderá contatar o <b>USUÁRIO </b>para
                        confirmar sua identidade antes do cumprimento do
                        requerimento.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                    <li>
                      <p>
                        Serão fornecidas confirmações da existência de
                        Tratamento de Dados Pessoais, em formato simplificado,
                        no prazo de até 15 (quinze) dias. Para os demais
                        requerimentos, a<b>BRASIL CASH </b>poderá apresentar sua
                        resposta no prazo de 30 (trinta) dias, o qual poderá ser
                        estendido a depender da natureza e complexidade do
                        requerimento.
                      </p>
                      <p>
                        <br />
                      </p>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              <h2>Modificações na Política de Privacidade</h2>
              <p>
                <br />
              </p>
              <ol id="l28">
                <li>
                  <p>
                    Essa Política será revista periodicamente pela
                    <b>BRASIL CASH </b>para adequá-la à prestação de Serviços,
                    mediante a exclusão, modificação ou inserção de novas
                    cláusulas e condições.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    As alterações deverão ser informadas ao <b>USUÁRIO </b>
                    mediante divulgação da versão atualizada desta Política.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <p>
                    Caso o <b>USUÁRIO </b>não concorde com as alterações, poderá
                    solicitar o encerramento do Contrato com a{" "}
                    <b>BRASIL CASH</b>, na forma prevista em tal instrumento.
                  </p>
                  <p>
                    <br />
                  </p>
                </li>
                <li>
                  <h2>
                    Com a realização do Cadastro e/ou utilização do Sistema de
                    Pagamentos e dos Serviços da BRASIL CASH pelo USUÁRIO, será
                    interpretado que o USUÁRIO tem ciência da versão vigente da
                    Política e não se opõe ao uso de seus Dados Pessoais na
                    forma
                  </h2>
                  <p>
                    <br />
                  </p>
                  <h2>
                    nela descrita, incluindo as últimas alterações realizadas,
                    que passarão a ser integralmente aplicáveis.
                  </h2>
                  <p>
                    <br />
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <h2>Esclarecimento de Dúvidas</h2>
              <p>
                <br />
              </p>
              <ol id="l29">
                <li>
                  <p>
                    Qualquer dúvida em relação à esta Política poderá ser
                    enviada ao Encarregado de Proteção de Dados da{" "}
                    <b>BRASIL CASH</b>
                    <a href="mailto:ouvidoria@br.cash" target="_blank">
                      , por e-mail:{" "}
                    </a>
                    <a href="mailto:ouvidoria@br.cash" target="_blank">
                      ouvidoria@br.cash
                    </a>
                  </p>
                </li>
              </ol>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p>Versão atualizada em março de 2021.</p>
          <p>
            <br />
          </p>
          <h2>BRASIL CASH SOLUÇÕES EM PAGAMENTOS LTDA.</h2>
        </div>
      </Container>
    </Dialog>
  );
};
