import { MoreVert, DeleteOutline } from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Menu,
  MenuItem,
  Box,
  Typography,
  Chip,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useToast } from "../../../../Hooks/toast";
import useCallAxios from "../../../../Hooks/useCallAxios";
import CustomButton from "../CustomButton";
import CodeIcon from "@mui/icons-material/Code";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const ApiTableList = ({ handleCopy, subAccountId }) => {
  const { addToast } = useToast();
  const [anchorEl, setAnchorEl] = useState(null);
  const [apiKeys, setApiKeys] = useState([]);

  const tooltipOpen = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const {
    fetchPOST: postCreateApi,
    requestLoading: loadingPostCreateApi,
    requestData: postCreateApiResponse,
    requestSuccess: successPostCreateApi,
  } = useCallAxios({ context: "subaccount" });

  const {
    fetchGET: getApiKeys,
    requestLoading: loadinggetApiKeys,
    requestData: getApiKeysResponse,
    requestSuccess: successGetApiKeys,
  } = useCallAxios({ context: "subaccount" });

  useEffect(() => {
    if (getApiKeysResponse) {
      setApiKeys(getApiKeysResponse.data);
    }
  }, [getApiKeysResponse]);

  useEffect(() => {
    if (apiKeys.length === 0) {
      getApiKeys({ id: subAccountId, afterID: "api" });
    }
  }, []);

  useEffect(() => {
    if (successPostCreateApi.POST) {
      addToast({
        title: "Successo",
        description: "Chave criada com sucesso.",
        type: "success",
      });
      getApiKeys({ id: subAccountId, afterID: "api" });
    }
  }, [successPostCreateApi.POST]);

  return (
    <>
      <CustomButton
        fullWidth
        startIcon={<CodeIcon />}
        onClick={() => postCreateApi({ id: subAccountId, afterID: "api" })}
        disabled={loadingPostCreateApi.POST}
      >
        Criar uma nova chave
      </CustomButton>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>API key</TableCell>
            <TableCell>API Secret</TableCell>
            <TableCell>Nome de usuário</TableCell>
            <TableCell>Senha</TableCell>
            <TableCell>Data criação</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(apiKeys).length === 0 ? (
            <TableRow>
              <TableCell align="left" width={10} colSpan={10}>
                Nenhuma chave foi adicionada
              </TableCell>
            </TableRow>
          ) : (
            <>
              {apiKeys?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="left" width={10}>
                    <IconButton id={row.apikey} onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                    <Menu
                      sx={{
                        display: anchorEl?.id !== row.apikey ? "none" : "block",
                      }}
                      key={index + row.apikey}
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      anchorEl={anchorEl}
                      open={tooltipOpen}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          width: "25ch",
                          zIndex: 9999999999,
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <DeleteOutline fontSize="inherit" />
                          Remover chave
                        </Box>
                      </MenuItem>
                    </Menu>
                  </TableCell>

                  <TableCell>
                    <Typography
                      fontFamily="monospace !important"
                      fontSize="14px"
                    >
                      {row.apikey}
                      <IconButton
                        onClick={() => handleCopy(row.apikey)}
                        disabled={!row.apikey}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Typography>
                  </TableCell>
                  <TableCell>
                    {" "}
                    <Typography
                      fontFamily="monospace !important"
                      fontSize="14px"
                    >
                      {row.apisecret}
                      <IconButton
                        onClick={() => handleCopy(row.apisecret)}
                        disabled={!row.apisecret}
                      >
                        <ContentCopyIcon />
                      </IconButton>
                    </Typography>
                  </TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.password}</TableCell>
                  <TableCell>
                    {new Date(row.created_at).toLocaleDateString()}
                  </TableCell>
                  <TableCell align="center">
                    {row.active ? (
                      <Chip label="Ativo" color="primary" />
                    ) : (
                      <Chip label="Inativo" color="error" variant="outlined" />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default ApiTableList;
