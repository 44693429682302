import styled from "styled-components";

export const OrganizerCardContainer = styled.div`
  width: 250px;
  height: 154px;
  padding: 16px;
  border-radius: 15px;
  border: 1px solid #d3e1f5;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
`;

export const OrganizerCardUpperBar = styled.div<{ bgColor: string }>`
  height: 8px;
  border-radius: 8px;
  margin-bottom: 16px;
  background-color: ${(props) => props.bgColor};
`;

export const OrganizerCardStatus = styled.div<{ bgColor: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.bgColor};
`;
