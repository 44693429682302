import { Box, Grid } from "@mui/material";
import styled from "styled-components";

export const OrganizerCardContainer = styled(Box)`
  padding: 16px;
  border-radius: 15px;
  border: 1px solid #d3e1f5;
  background-color: #fafbfc;
`;

export const OrganizerCardDetail = styled(Box)`
  padding: 16px;
  border-radius: 15px;
  border: 1px solid #d3e1f5;
`;
