import styled from "styled-components";
import { IconButton, FormControl } from "@mui/material";
import { Button } from "../../Atoms";

import { Form } from "@unform/web";

export const Container = styled.div`
  display: flex;
  gap: 1rem;
  position: relative;
  flex-direction: column;
  height: auto;
  min-width: 600px;
  overflow-y: hidden;

  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 8px 0px rgb(0 0 0 / 12%);
  background-color: #fff;
  width: 50%;
  border-radius: 10px;
  padding: 2rem;
`;

export const ModalHeader = styled.div`
  margin-bottom: 1.5rem;
`;

export const ModalMain = styled.div``;

export const FormStyled = styled(Form)`
  && {
    display: flex;
    flex-direction: column;

    .error {
      position: relative;
      margin-bottom: 0.5rem;
      p {
        position: absolute;
        color: red;
        font-size: 0.85em;

        top: -5px;
      }
    }
  }
`;

export const FormButton = styled(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 3rem;
    width: 100%;
    font-weight: 700;
    margin-top: 1rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
`;

export const IconBtn = styled(IconButton)`
  && {
    position: absolute;
    top: 2.3rem;
    right: 3rem;
    margin-left: 10px;
    padding: 0;
    width: 15px;
    height: 15px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      color: #303f9f;
      background-color: #fff;
      border: none;

      .close-btn {
        font-size: 1.9em;
      }

      :hover {
        background-color: #e0e0e0;
      }
    }
  }
`;

export const StyledFormControl = styled(FormControl)`
  && {
    margin-top: 1.3rem;
    margin-bottom: 0.5rem;
    .MuiPaper-root
      .MuiMenu-paper
      .MuiPopover-paper
      .MuiPaper-elevation8
      .MuiPaper-rounded {
      top: 330px !important;
    }
  }
`;

export const DoubleInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;

  .account {
    width: 100%;
  }
`;
