import { useEffect, useState } from "react";
import { useReceiptsData, useReceiptsDataV2 } from "./Receipts";
import useCallAxios from "./useCallAxios";
import { useFilter } from "./useFilter";
import html2PDF from "jspdf-html2canvas";

const filterQuery = (values) => ({ ...values });

export const useReportExcel = () => {
  const context = `excelStatement`;

  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });

  const handleGET = (params) => {
    fetchGET({
      queryParameters: filterQuery(params),
      responseType: "blob",
    });
  };

  return {
    reset,
    loading: requestLoading,
    state: requestData,
    success: requestSuccess,
    handleGET,
  };
};

export const useReportCSV = () => {
  const context = `csvStatement`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });

  const handleGET = (params) => {
    fetchGET({
      queryParameters: filterQuery(params),
      responseType: "blob",
    });
  };

  return {
    reset,
    loading: requestLoading,
    state: requestData,
    success: requestSuccess,
    handleGET,
  };
};

export const useInvoicesDownload = () => {
  const { getFilterObj } = useFilter();
  const extractFilterObj: any = getFilterObj("receipt");
  const [preparingFiles, setPreparingFiles] = useState(false);

  let fileName = `comprovantes(${extractFilterObj?.start_date}_${extractFilterObj?.end_date}).pdf`;

  const DownloadAsPDF = () => {
    const elements: any = document.getElementsByClassName("printcontent");

    const generatePdf: any = html2PDF;

    generatePdf(elements, {
      autoResize: false,
      html2canvas: { scale: 0.7 },
      jsPDF: {
        format: [800, 360],
      },
      margin: {
        left: 30,
        top: 10,
      },
      imageType: "image/jpeg",
      // output: fileName,
      init: () => {
        setPreparingFiles(true);
      },
      success: (pdf) => {
        pdf.save(fileName);
        setPreparingFiles(false);
      },
    });
  };

  const fetchAllInvoicesByRange = () => {
    DownloadAsPDF();
  };

  return { fetchAllInvoicesByRange, preparingFiles };
};
