import {
  ButtonBase,
  Container,
  Grid,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { ModalLoader } from "../../../Components/Atoms/ModalLoader";
import { Fragment } from "react/jsx-runtime";
import { ScreenGreeting } from "../../../Components/Molecules";
import intl from "react-intl-universal";
import { OrganizerCard } from "./Card";
import { Button } from "../../../Components/Atoms";
import {
  useCreateSubAccount,
  useSubAccountsList,
} from "../../../Hooks/useSubAccounts";
import { useCallback, useEffect, useState } from "react";
import { useToast } from "../../../Hooks/toast";
import CustomButton from "./CustomButton";
import DetailsOrganizer from "./Details";
import { useEventListener } from "../../../Hooks";

export function Organizers() {
  const [selectedOrganizer, handleSelectedOrganizer] = useState(null);
  const { addToast } = useToast();

  const [showForm, setShowForm] = useState(false);
  const [organizerName, setOrganizerName] = useState("");

  const {
    handleGET: fetchSubAccountsGET,
    loading,
    state: subAccounts,
  } = useSubAccountsList();

  const {
    handlePOST: createSubAccountPOST,
    loading: loadingCreate,
    success: isSuccessCreateSubAccount,
    reset: resetCreateSubAccountPOST,
    state: createResult,
  } = useCreateSubAccount();

  const createSubAccount = useCallback(() => {
    createSubAccountPOST({ name: organizerName });
    setOrganizerName("");
    setShowForm(false);
  }, [subAccounts, organizerName]);

  useEffect(() => {
    const getParams = () => {
      const params = new URLSearchParams(window.location.search);
      const subAccountFiltered = subAccounts.find(
        (item) => item.subAccountId === params.get("ponto")
      );
      if (subAccountFiltered) {
        handleSelectedOrganizer(subAccountFiltered);
      }
    };

    if (subAccounts) {
      getParams();
      const url = new URL(window.location.href);
      url.searchParams.delete("ponto"); // replace 'search' with your param name
      window.history.pushState({}, "", url.href);
    }
  }, [subAccounts]);

  useEffect(() => {
    fetchSubAccountsGET({});
  }, []);

  useEffect(() => {
    if (isSuccessCreateSubAccount.POST) {
      addToast({
        title: "Successo",
        description: "Novo ponto de venda criado com sucesso.",
        type: "success",
      });

      fetchSubAccountsGET({});
    }
  }, [isSuccessCreateSubAccount.POST]);

  useEventListener("refresh-balance", () => fetchSubAccountsGET({}));

  return (
    <Grid container>
      <ScreenGreeting
        title={intl.get(`words.organizers`)}
        subTitle="Faça o gerenciamento dos seus pontos de venda com facilidade."
      />

      {selectedOrganizer !== null ? (
        <Container maxWidth="xl">
          <DetailsOrganizer
            selectedOrganizer={selectedOrganizer}
            handleBack={() => handleSelectedOrganizer(null)}
          />
        </Container>
      ) : (
        <Container>
          <div
            className="flex-row justify-content-end"
            style={{ marginBottom: "1rem" }}
          >
            {!showForm ? (
              <CustomButton onClick={() => setShowForm(true)}>
                Criar Ponto de Venda
              </CustomButton>
            ) : (
              <form
                className="flex-row"
                style={{ columnGap: "16px" }}
                action="#"
                onSubmit={createSubAccount}
              >
                <TextField
                  placeholder="Nome"
                  size="small"
                  value={organizerName}
                  onChange={(e) => setOrganizerName(e.target.value)}
                />
                <Button type="submit" loading={loadingCreate.POST}>
                  Criar
                </Button>
                <Button action="cancel" onClick={() => setShowForm(false)}>
                  Cancelar
                </Button>
              </form>
            )}
          </div>

          <Grid container spacing={2}>
            {Array.isArray(subAccounts) &&
              (subAccounts.length === 0 ? (
                <p> Não há organizador cadastrado. </p>
              ) : (
                subAccounts.map((subAccount, i) => (
                  <Grid item>
                    <ButtonBase
                      onClick={() => handleSelectedOrganizer(subAccount)}
                    >
                      <OrganizerCard
                        key={i}
                        name={subAccount.name}
                        upperBarColor="#36D9FF"
                        statusColor="#4CB592"
                        totalValue={subAccount.available}
                      />
                    </ButtonBase>
                  </Grid>
                ))
              ))}
          </Grid>
        </Container>
      )}
    </Grid>
  );
}
