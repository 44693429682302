import moment from "moment";

import {
  Card,
  CardsWrapper,
  ChartWrapper,
  Container,
  Content,
  DateInfo,
  LabelsContainer,
} from "./styles";

import { useEffect, useState } from "react";
import useCallAxios from "../../../Hooks/useCallAxios";
import { formatCurrency } from "../../../Utils/formatters";
import { Loading } from "../../Atoms";
import { productList } from "./products";
import { TableFilter } from "../Filters";
import { useFilter } from "../../../Hooks/useFilter";

export const PixCardsDashboard = () => {
  const [dataCard, setDataCard] = useState([]);

  const context = "accountPixTotals";

  const { fetchGET: fetchPixTotals, requestData: response } = useCallAxios({
    context,
    responseType: "",
  });

  const [loading, setLoading] = useState(true);

  const { getFilterObj } = useFilter();
  const pixTotalsObj = getFilterObj("pixTotals");

  useEffect(() => {
    fetchPixTotals({ queryParameters: pixTotalsObj });
  }, [pixTotalsObj]);

  useEffect(() => {
    try {
      const dataPix = response as any;
      setDataCard(dataPix.data);
    } catch (error) {
      console.log(error);
    }
  }, [response]);

  useEffect(() => {
    try {
      setLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [dataCard]);

  if (loading) {
    return (
      <Container>
        <CardsWrapper>
          <Content>
            <Loading />
          </Content>
        </CardsWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <TableFilter
        tableName="pixTotals"
        setPageToFirst={() => console.log("")}
      />
      <CardsWrapper>
        {dataCard && dataCard.length ? (
          dataCard.map((card, i) => (
            <Card key={i + card.key}>
              <p>
                {productList.find(({ key }) => key === card.key).description}
              </p>
              <Content>
                <p>{formatCurrency(Math.abs(card.amount))}</p>
              </Content>
              <small>Taxas: {formatCurrency(Math.abs(card.tax))}</small>
            </Card>
          ))
        ) : (
          <CardsWrapper>
            <p>Você não tem movimentação para o filtro selecionado. </p>
          </CardsWrapper>
        )}
      </CardsWrapper>
    </Container>
  );
};
