import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const HeaderMainContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TimerContainer = styled.div`
  display: flex;
  margin-left: 4rem;
  gap: 0.1rem;

  color: #303f9f;
  font-size: 0.7em;
`;

export const Clock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1rem;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  position: relative;
  overflow-x: hidden;
  width: 450px;
  height: 300px;

  padding: 1rem;

  p {
    margin-top: 2rem;
    width: fit-content;

    color: ${(props) => props.theme.background.dark};
    font-size: 1.3em;
  }
`;

export const CloseModalButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 0.5rem;

  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 0.8em;
  color: #4a4a4a;

  svg {
    font-size: 1.5em;
  }

  &:hover,
  &:active,
  svg:hover,
  svg:active {
    text-decoration: underline;
  }
`;

export const WarningSvg = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  svg {
    font-size: 4.5em;
    color: ${(props) => props.theme.background.dark};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 1.5rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 160px;

  border-radius: 0.2rem;

  background-color: ${(props) => props.theme.background.dark};
  color: white;

  svg {
    color: white;
  }
`;

export const RestoreSessionButton = styled.button`
  border: none;

  padding: 0.6em 1rem;
  border-radius: 0.2rem;

  background-color: ${(props) => props.theme.background.dark};
  color: white;

  font-weight: bold;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    outline: 1.5px solid;
    color: ${(props) => props.theme.background.dark};
    background: transparent;
  }
`;
export const LogoutButton = styled.button`
  background: none;
  border: none;

  padding: 0.5em 1rem;
  border-radius: 0.2rem;

  outline: 1.5px solid;
  color: #737373;
  font-weight: bold;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    background-color: #e11919;
    color: white;
  }
`;
