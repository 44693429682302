import {
  List,
  Paper,
  ListItem,
  ListItemIcon,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup,
  useMediaQuery,
  ListItemSecondaryAction,
  Typography,
} from "@mui/material";
import { Loading, Button, BalanceWarning } from "../../../Components/Atoms";
import { ArrowBack, Search, AccountBalanceWallet } from "@mui/icons-material";
import { boleto } from "boleto-brasileiro-validator";
import { ScreenGreeting } from "../../../Components/Molecules";
import { useCallback, useState } from "react";
import LogoBrasilCashShort from "../../../Assets/brasilcash@logo_short.png";
import { useStyles } from "./styles";
import InputMask from "react-input-mask";
import { receiptBankMask, receiptChargeMask } from "../../../Utils/masks";
import { useConsultBarCodeData } from "../../../Hooks/index";
import BarcodeDataDetails from "../../../Components/Molecules/BarcodeDataDetails";
import { FinalStepPayment } from "./paymentFinalStep";
import { PasswordConfirm } from "../../../Components/Molecules/PasswordConfirm";
import { useEffect } from "react";
import { useAccountBalanceData, useBarcodePayment } from "../../../Hooks";
import { formatCurrency } from "../../../Utils/formatters";
import moment from "moment";
import { ModalLoader } from "../../../Components/Atoms/ModalLoader";
import { useToast } from "../../../Hooks/toast";
/* import { CopyToClipboard } from "react-copy-to-clipboard"; */

export const Payments = () => {
  const { addToast } = useToast();
  const classes = useStyles();
  const [receiptCode, setReceiptCode] = useState();
  const [paymentType, setPaymentType] = useState();
  const [requirePassword, setRequirePassword] = useState(false);
  const [step, setStep] = useState(0);
  const [isReceiptErrored, setIsReceiptErrored] = useState(false);
  const {
    handleGET: fetchConsultBarCodeData,
    loading: loadingBarCodeDetails,
    state: barCodeDetails,
  } = useConsultBarCodeData();

  const {
    success: paymentStatus,
    handlePOST: fetchBarcodePayment,
    loading: paymentLoading,
    state: paymentState,
  } = useBarcodePayment();

  //VERIFYBALANCE
  const { state: balanceValue, handleGET: fetchAccountBalanceGET } =
    useAccountBalanceData();

  const matches = useMediaQuery("(min-width: 1230px)");

  const handlePayment = useCallback(() => {
    setRequirePassword(true);
  }, [barCodeDetails]);

  const handleFinishTransaction = useCallback(
    (pass) => {
      setRequirePassword(false);
      if (pass.length !== 4) return;

      let barcode_line = receiptCode.replace(/\./g, "");
      barcode_line = barcode_line.replace("-", "");
      barcode_line = barcode_line.replace(/\s/g, "");

      fetchBarcodePayment({
        validation: {
          password_combination: pass,
        },
        scheduled_to: moment().format("YYYY-MM-DD"),
        payment_mode: {
          type: "account",
        },
        barcode_line,
      });
    },
    [barCodeDetails]
  );

  const handleSubmit = useCallback(() => {
    if (!receiptCode) {
      setIsReceiptErrored(true);
      return;
    }
    if (!boleto(receiptCode)) {
      setIsReceiptErrored(true);
      return;
    } else {
      let barcode_line = receiptCode.replace(/\./g, "");
      barcode_line = barcode_line.replace("-", "");
      barcode_line = barcode_line.replace(/\s/g, "");

      fetchConsultBarCodeData({
        barcode_line,
      });
      fetchAccountBalanceGET();
    }
  }, [receiptCode, fetchConsultBarCodeData, fetchAccountBalanceGET]);

  useEffect(() => {
    !!barCodeDetails && !loadingBarCodeDetails.GET && setStep(1);
  }, [barCodeDetails, loadingBarCodeDetails]);

  useEffect(() => {
    paymentStatus.POST === true && !paymentLoading.POST && setStep(2);
  }, [paymentStatus, paymentLoading]);

  const paymentHomeScreen = (
    <>
      <ScreenGreeting
        title={"Pagamentos"}
        subTitle={
          "Cole ou digite o código do boleto no campo abaixo para realizar o pagamento"
        }
      />
      <div className={classes.box}>
        <Typography className={classes.subTitle}>Código do boleto</Typography>
        <div className={classes.inputBox}>
          <div
            style={
              isReceiptErrored
                ? { border: "1px solid red", borderRadius: 4, width: "100%" }
                : { border: 0, width: "100%" }
            }
          >
            <InputMask
              className={classes.receiptInput}
              mask={
                receiptCode?.substring(0, 1) === "8"
                  ? receiptChargeMask
                  : receiptBankMask
              }
              value={receiptCode}
              onChange={(e) => {
                setReceiptCode(e.target.value);
              }}
              onFocus={() => {
                setIsReceiptErrored(false);
              }}
              placeholder="Cole ou digite o código"
              maskChar={null}
            />
          </div>
          <Button style={{ width: 66, height: 66 }} onClick={handleSubmit}>
            <Search style={{ fontSize: 30 }} />
          </Button>
        </div>

        {isReceiptErrored && (
          <p className={classes.receiptError}>
            Erro no código do boleto, por favor revise o código digitado
          </p>
        )}
      </div>
      <div className={classes.box}>
        <Typography className={classes.title}>Forma de pagamento</Typography>
        <div className={classes.listContainer}>
          <List
            dense
            style={{
              paddingBottom: 0,
              borderRadius: 5,
              height: "100%",
            }}
          >
            <FormControl
              style={{ width: "100%" }}
              component="fieldset"
              className={classes.formControl}
            >
              <Paper elevation={3} className={classes.paper}>
                <RadioGroup
                  value={paymentType}
                  onChange={(e) => setPaymentType(e.target.value)}
                >
                  <ListItem
                    style={{
                      borderBottom: "1px solid #e0e0e0",
                      height: "100%",
                      padding: 20,
                      flexDirection: matches ? "row" : "column",
                    }}
                  >
                    <FormControlLabel
                      value="balance"
                      control={<Radio color="primary" checked={true} />}
                      label="Saldo em conta"
                    />
                    <ListItemSecondaryAction
                      style={{
                        justifyContent: "start",
                      }}
                    >
                      <ListItemIcon
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          padding: 0,
                        }}
                      >
                        <img
                          src={LogoBrasilCashShort}
                          height={30}
                          width="auto"
                        />
                      </ListItemIcon>
                    </ListItemSecondaryAction>
                  </ListItem>
                </RadioGroup>
              </Paper>
            </FormControl>
          </List>
        </div>
      </div>
    </>
  );

  const finishPayment = (
    <div className={classes.receiptContainer}>
      <ScreenGreeting
        title={"Finalizar pagamento"}
        subTitle={
          "confira os detalhes da cobrança antes de confirmar a transação!"
        }
      />
      <p
        className={classes.backButton}
        onClick={() => {
          setReceiptCode("");
          setStep(0);
        }}
      >
        <ArrowBack />
        Voltar
      </p>
      <BarcodeDataDetails receipt={barCodeDetails} />
      <Button onClick={handlePayment} loading={paymentLoading.POST}>
        Finalizar pagamento
      </Button>
      <PasswordConfirm
        submitPassword={(e) => handleFinishTransaction(e)}
        open={requirePassword}
        onClose={() => setRequirePassword(false)}
      />
    </div>
  );

  return (
    <>
      <ModalLoader
        open={loadingBarCodeDetails.GET || paymentLoading.POST}
        text={
          loadingBarCodeDetails.GET
            ? "Carregando informações"
            : "Finalizando pagamento"
        }
      />
      <div className={classes.container}>
        {step === 0 && paymentHomeScreen}
        {step === 1 && finishPayment}
        {step === 2 && (
          <FinalStepPayment
            refreshStep={() => {
              setStep(0);
              setReceiptCode();
            }}
            paymentSuccess={paymentStatus.POST}
            paymentData={paymentState}
          />
        )}
      </div>
    </>
  );
};
