import moment from "moment";
import * as Yup from "yup";

export function useSchema(validateSchema) {
  const {
    bollCPF_CNPJ,
    boolDiscountFixed,
    boolDiscountDate,
    boolFeeDate,
    boolLimitDate,
  } = validateSchema;

  Yup.setLocale({
    date: {
      min: "Vencimento deve ser maior que a data atual",
    },
  });

  let schema = Yup.object().shape({
    taxID: Yup.string()
      .required("CPF/CNPJ obrigatório.")
      .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
    name: Yup.string().required("Nome obrigatório"),
    expireDate: Yup.date()
      .min(moment())
      .required("Vencimento obrigatório")
      .typeError("Digite uma data válida"),
    hasAddress: Yup.boolean(),
    hasPenalty: Yup.boolean(),
    hasPenaltyDate: Yup.boolean(),
    hasDiscountFixed: Yup.boolean(),
    hasDiscountPercent: Yup.boolean(),
    hasDiscountDate: Yup.boolean(),
    hasLimitDate: Yup.boolean(),
    value: Yup.number()
      .typeError("Digite o valor do link")
      .required("Digite o valor do link")
      .moreThan(49.99, "Valor deve ser maior ou igual à 50.00 R$"),

    //ADDRESS

    cep: Yup.string().when("hasAddress", {
      is: true,
      then: Yup.string().required("CEP obrigatório"),
    }),

    number: Yup.number().when("hasAddress", {
      is: true,
      then: Yup.number().required().typeError("Número obrigatório"),
    }),

    address: Yup.string().when("hasAddress", {
      is: true,
      then: Yup.string().required("Endereço obrigatório"),
    }),
    city: Yup.string().when("hasAddress", {
      is: true,
      then: Yup.string().required("Cidade obrigatória"),
    }),
    state: Yup.string().when("hasAddress", {
      is: true,
      then: Yup.string().required("Estado obrigatório"),
    }),
    district: Yup.string().when("hasAddress", {
      is: true,
      then: Yup.string().required("Bairro obrigatório"),
    }),

    //FEEDATE
    penaltyDate: Yup.date().when("hasPenaltyDate", {
      is: true,
      then: Yup.date()
        .min(moment())
        .required("Data de multa obrigatória")
        .typeError("Digite uma data válida")
        .test(
          "valid",
          "Data deve ser posterior à data de expiração do boleto",
          () => boolFeeDate === true
        ),
    }),

    limitDate: Yup.date().when("hasLimitDate", {
      is: true,
      then: Yup.date()
        .min(moment())
        .required("Data limite obrigatória")
        .typeError("Digite uma data válida")
        .test(
          "valid",
          "Data deve ser posterior à data de expiração do boleto e à data de aplicação da multa.",
          () => boolLimitDate === true
        ),
    }),
    //FEEVALUE
    penaltyValue: Yup.number().when("hasPenalty", {
      is: true,
      then: Yup.number()
        .typeError("Digite o valor da multa")
        .required("Digite o valor da multa"),
      // .moreThan(0, "A multa deve ser maior que R$ 0,00"),
    }),

    penaltyFee: Yup.number().when("hasPenalty", {
      is: true,
      then: Yup.number()
        .typeError("Digite o valor dos juros")
        .lessThan(20.1, "A Porcentagem de juros deve ser menor que 20%"),
    }),

    discountDate: Yup.date().when("hasDiscountDate", {
      is: true,
      then: Yup.date()
        .required("Vencimento obrigatório")
        .typeError("Digite uma data válida")
        .test(
          "valid",
          "Data deve ser anterior à data de expiração do boleto",
          () => boolDiscountDate === true
        ),
    }),

    discountPercent: Yup.number().when("hasDiscountPercent", {
      is: true,
      then: Yup.number()
        .typeError("Digite o valor do desconto")
        .required("Digite o valor do desconto")
        .moreThan(0, "Desconto deve ser maior que 0%")
        .lessThan(100, "Desconto deve ser menor que 100%"),
    }),
    discountFixed: Yup.number().when("hasDiscountFixed", {
      is: true,
      then: Yup.number()
        .typeError("Digite o valor do desconto")
        .required("Digite o valor do desconto")
        .moreThan(0, "Desconto deve ser maior que 0")
        .test(
          "valid",
          "Desconto deve ser menor que o valor do boleto",
          () => boolDiscountFixed === true
        ),
    }),
  });

  return schema;
}
