import { StoreButton, StoreAnchor } from "./styles";
import ios from "../../../Assets/appstore.png";
import android from "../../../Assets/play.png";
const imageLinks = {
  ios,
  android,
};

const MobileStoreButton = ({ store, url, linkProps }) => {
  const storeUrl = imageLinks[store];

  return (
    <>
      <StoreButton height={50} width={150}>
        <StoreAnchor
          storeUrl={storeUrl}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          {...linkProps}
        >
          &nbsp;
        </StoreAnchor>
      </StoreButton>
    </>
  );
};

export default MobileStoreButton;
