import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export function useSubAccountsList() {
  const context = `subAccountsList`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });
  const state = (requestData as any).data;
  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) } as any);
  };

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleGET,
  };
}

export function useCreateSubAccount() {
  const context = `subAccountCreate`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({ context });
  const state = (requestData as any).status;
  const handlePOST = (body) => {
    fetchPOST({ context, body } as any);
  };

  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
}

export function useSubAccountDetails() {
  const context = `subAccountDetails`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });
  const state = (requestData as any)?.data;

  const handleGET = (params) => {
    fetchGET({ context, id: params.id } as any);
  };
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleGET,
  };
}

export function useSubAccountGetQRCode() {
  const context = `subAccountQRCode`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({ context });
  const state = (requestData as any)?.data;

  const handleGET = (params) => {
    fetchGET({ context, id: params.id, afterID: "qrcode" } as any);
  };
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handleGET,
  };
}

export function useSubAccountCreateQRCode() {
  const context = `subAccountQRCodeCreate`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({ context });
  const state = (requestData as any)?.data;

  const handlePOST = (params) => {
    fetchPOST({
      context,
      id: params.id,
      afterID: "qrcode",
      body: { amount: params.amount },
    } as any);
  };
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
}

export function useSubAccountAmountAdd() {
  const context = `subAccountBalanceAdd`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({ context });
  const state = (requestData as any)?.data;

  const handlePOST = (params) => {
    fetchPOST({
      context,
      id: params.id,
      afterID: "add",
      body: { amount: params.amount },
    } as any);
  };
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
}

export function useSubAccountAmountWithdraw() {
  const context = `subAccountBalanceWithdraw`;
  const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
    useCallAxios({ context });
  const state = (requestData as any)?.data;

  const handlePOST = (params) => {
    fetchPOST({
      context,
      id: params.id,
      afterID: "withdraw",
      body: { amount: params.amount },
    } as any);
  };
  return {
    reset,
    loading: requestLoading,
    state,
    success: requestSuccess,
    handlePOST,
  };
}
