import React, { useRef, useCallback, useState, ChangeEvent } from "react";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  Container,
  Content,
  LoginFormContainer,
  LoginFormContent,
  NewAccount,
  LoginCarroselContent,
  CustomBreadcrumbs,
} from "./styles";
import { CompanyLogo, Input, Button } from "../../../Components/Atoms";
import { handleTaxIDInput } from "../../../Utils/masks";
import { AuthContext } from "../../../Contexts/Auth";
import { useContext } from "react";
import { useEffect } from "react";
import TypeWriter from "typewriter-effect";
import MobileStoreButton from "../../../Components/Molecules/MobileStoreButton";
import { ButtonBase, Typography } from "@mui/material";
import VerificationCodeLogin from "../VerificationCode";
import { PrivacyPolicyModal } from "../Privacy";
import ForgotPassword from "../ForgotPassword";
import { useCaptcha } from "../../../Hooks/useCaptcha";
import { validateCPF_CNPJ } from "../../../Utils/CPF_CNPJValidation";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { useGetSiteOptions } from "../../../Hooks/WhiteLabel";
import { loadItem } from "../../../Utils";
import { url } from "inspector";
import MobilePage from "../MobilePage";
import { detectMobileSystem } from "../../../Utils/detectMobileSytem";

interface ILoginFormData {
  password: string;
  username: string;
}

const Login: React.FC = () => {
  //SET CAPTCHA FAZ A REQ DE LOGIN
  const { setCaptcha, loading } = useCaptcha();
  const formRef = useRef<FormHandles>(null);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [inputMask, setInputMask] = useState("");
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const [step, setStep] = useState(0);
  const { firstStepData } = stateAuthContext;
  const { title, app, login_image, site } = loadItem("Options");
  const [redirectToMobile, setRedirectToMobile] = useState(false);
  const appOptions = sessionStorage.getItem("Options");
  const [appUrlContext, setAppUrlContext] = useState("");
  const [isOperator, setIsOperator] = useState(false);

  useEffect(() => {
    let appData: any;

    if (appOptions) appData = JSON.parse(appOptions).app;

    var userAgent = navigator.userAgent || navigator.vendor;
    var isMobile = /Android|webOS|iPhone|iPad|iPod/i.test(userAgent);

    var appContext = detectMobileSystem(appData);
    setAppUrlContext(appContext);

    if (isMobile) {
      setRedirectToMobile(true);
    }
  }, []);

  const handleClose = useCallback(() => {
    setOpenPrivacyPolicy(!openPrivacyPolicy);
  }, [openPrivacyPolicy]);

  // Chamada do hook e lógica de redirecionamento para segunda etapa
  useEffect(() => {
    if (Object.keys(firstStepData).length > 0) {
      setStep(1);
    } else if (Object.keys(firstStepData).length === 0) {
      setStep(0);
    }
  }, [firstStepData]);

  // useEffect(() => {
  //   if (!!requestError && Object.keys(requestError).length > 0) {
  //     formRef.current?.setErrors(requestError);
  //   }
  // }, [requestError]);

  const handleSubmit = useCallback(
    async (data: ILoginFormData, isOperator) => {
      try {
        if (isOperator) {
          const schemaOperator = Yup.object().shape({
            username: Yup.string()
              .email("Insira um e-mail válido.")
              .required("E-mail obrigatório"),
            password: Yup.string().min(
              8,
              "Senha obrigatória, no mínimo 8 digitos."
            ),
          });
          await schemaOperator.validate(data, {
            abortEarly: false,
          });
        } else {
          const schemaDefault = Yup.object().shape({
            username: Yup.string()
              .required("CPF/CNPJ obrigatório.")
              .test(
                "valid",
                "CPF/CNPJ inválido",
                () => validateCPF_CNPJ(data.username) === true
              ),
            password: Yup.string().min(
              8,
              "Senha obrigatória, no mínimo 8 digitos."
            ),
          });

          await schemaDefault.validate(data, {
            abortEarly: false,
          });
        }
        setCaptcha(data);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [loading]
  );

  const LoginStep = (
    <LoginFormContent>
      <h1>Bem vindo!</h1>
      <span> Para acessar, por favor faça o login.</span>
      <Form ref={formRef} onSubmit={(data) => handleSubmit(data, isOperator)}>
        {isOperator ? (
          <Input
            value={inputMask}
            type="email"
            name="username"
            placeholder="E-mail de operador"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputMask(e.target.value);
            }}
          />
        ) : (
          <Input
            value={inputMask}
            type="text"
            name="username"
            placeholder="CPF ou CNPJ"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setInputMask(handleTaxIDInput(e.currentTarget.value));
            }}
          />
        )}

        {/* <Link
          to="/"
          onClick={() => {
            setStep(2);
          }}
        >
          esqueceu sua senha?
        </Link> */}
        <Input
          id="passwordInput"
          name="password"
          placeholder="Senha"
          type="password"
        />

        <Button type="submit" loading={loading}>
          Entrar
        </Button>
      </Form>

      <NewAccount>
        <MobileStoreButton
          store="ios"
          url={app?.ios_url}
          linkProps={{ title: "iOS Store Button" }}
        />
        <h6>ou</h6>
        <MobileStoreButton
          store="android"
          url={app?.android_url}
          linkProps={{ title: "Android Store Button" }}
        />
      </NewAccount>
      <div style={{ marginTop: 32, textAlign: "center", zIndex: 99999 }}>
        {isOperator ? (
          <ButtonBase
            onClick={() => {
              setIsOperator(false);
              setInputMask("");
            }}
          >
            <Typography variant="subtitle2" color="red">
              Sair do modo operador!
            </Typography>
          </ButtonBase>
        ) : (
          <ButtonBase
            onClick={() => {
              setIsOperator(true);
              setInputMask("");
            }}
          >
            <Typography variant="subtitle2" color="red">
              Entrar como operador!
            </Typography>
          </ButtonBase>
        )}
      </div>
    </LoginFormContent>
  );

  return redirectToMobile ? (
    <MobilePage appUrl={appUrlContext} title={title} />
  ) : (
    <Container>
      <Content>
        <LoginCarroselContent
          style={{ backgroundImage: `url(${login_image})` }}
        >
          <section />
          <div
            style={{
              zIndex: 1000,
              position: "absolute",
              marginLeft: 40,
              marginTop: 40,
            }}
          >
            <CompanyLogo width={270} height={70} isWhiteLogo={true} />
          </div>

          <div
            style={{
              zIndex: 1000,
              position: "absolute",
              top: "40%",
              display: "flex",
              marginLeft: 40,
              flexDirection: "column",
              width: "50%",
            }}
          >
            <h4
              style={{
                fontSize: "1.5em",
                marginBottom: "1.2em",
              }}
            >
              Simples e completa, agora na web.
            </h4>
            <Typography style={{ fontSize: "3em" }} variant="h4">
              A melhor solução em
              <span>
                <TypeWriter
                  options={{
                    strings: ["conta digital", "pagamentos", "maquininhas"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
              para pessoas físicas e jurídicas
            </Typography>
          </div>
        </LoginCarroselContent>
        <LoginFormContainer>
          {step === 0 && LoginStep}
          {step === 1 && <VerificationCodeLogin />}
          {step === 2 && <ForgotPassword />}
          <CustomBreadcrumbs separator={false}>
            <p
              onClick={() => {
                window.open(site);
              }}
            >
              {title}
            </p>
            <p
              onClick={() => {
                setOpenPrivacyPolicy(true);
              }}
            >
              Política de privacidade
            </p>
          </CustomBreadcrumbs>
        </LoginFormContainer>
      </Content>
      <PrivacyPolicyModal open={openPrivacyPolicy} onClose={handleClose} />
    </Container>
  );
};

export default Login;
