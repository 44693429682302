import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { menuRoutes } from "../../../Constants/RoutesByType";
import { Container, MenuItem, Menu, Subtitle } from "./styles";
import { useState } from "react";

interface IMenuRoutes {
  path: string;
  label: string;
  icon?: any;
  isHidden?: boolean;
}

export const MenuNavigation = ({ accountData }) => {
  let location = useLocation();

  const menuRouteOptions: IMenuRoutes[] = menuRoutes;

  const checkUserRole = (menus: IMenuRoutes[], userRole) => {
    if (userRole === "user")
      return menus.filter((item) => item.path !== "/operadores");
    if (userRole === "subAccount")
      return menus.filter(
        (item) =>
          !item.path.match(
            /transferencia|estornos|pagamento|boletos|comprovantes|pontos-de-venda|perfil|operadores/
          )
      );

    return menus;
  };

  return (
    <Container container spacing={2}>
      <Grid item xs={12}>
        <Subtitle gutterBottom variant="subtitle2">
          MENU
        </Subtitle>
        <Menu>
          {checkUserRole(menuRouteOptions, accountData.userRole)?.map(
            (el, index) => {
              if (el?.isHidden) {
                return;
              }
              const Icon = el?.icon;
              return (
                <Link key={index} to={el?.path}>
                  <MenuItem
                    style={{ padding: 10 }}
                    key={index}
                    isSelected={location.pathname === el?.path}
                  >
                    <ListItemIcon>
                      <Icon />
                    </ListItemIcon>
                    <ListItemText primary={el.label} />
                  </MenuItem>
                </Link>
              );
            }
          )}
        </Menu>
      </Grid>
    </Container>
  );
};
