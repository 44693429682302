import { useCallback, useRef, useState } from "react";

import {
  Container,
  Content,
  ReceiptHeader,
  ReceiptTitle,
  ReceiptDetailsContent,
  ReceiptFooter,
  IconBtn,
  Footer,
  Border,
} from "./receiptDetailsStyle";
import { Close, GetApp, PrintOutlined } from "@mui/icons-material";
import { formatCurrency } from "../../../Utils/formatters";
import { CompanyLogo } from "../../Atoms";
import { useToast } from "../../../Hooks/toast";

import { CircularProgress } from "@mui/material";
import { useReactToPrint } from "react-to-print";
import axios from "axios";
import { saveAs } from "file-saver";
import moment from "moment";

const TYPERECEIPT = {
  TRANSFER: 1,
  PAYMENT: 2,
};

const typeReceipt = {
  refound: "Detalhes do estorno",
  [TYPERECEIPT.TRANSFER]: "Comprovante de transferência",
  [TYPERECEIPT.PAYMENT]: "Comprovante de pagamento",
  "": "Comprovante de pix",
};

const ReceiptDetails = ({ receipt, noModal, onClose, type = "" }) => {
  const [loadingDownload, setLoadingDownload] = useState(false);

  const { addToast } = useToast();

  const parsedType = receipt.type || type;

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: `
    @page { size: auto;  margin: 0mm; }

    html, body, p, h1, h2, h3, h4 {
      color: #000 !important;
    } 
    #print-title: {
      font-size:14px !important;
    }
    #print {
     height: 100vh;
     overflow: hidden;
    }
    #print-hidden {
      display: none !important;
    }
    `,
  });

  const handlePDFDownload = useCallback(() => {
    setLoadingDownload(true);
    try {
      axios
        .post(
          "https://bcpag-html-compiler.azurewebsites.net/compiler/receipt",
          receipt,
          {
            responseType: "blob",
          }
        )
        .then((res) => {
          setLoadingDownload(false);
          const pdfBlob = new Blob([res.data], { type: "application/pdf" });
          saveAs(pdfBlob, "comprovante.pdf");
        });
    } catch {
      addToast({
        title: "Erro ao baixar comprovante!",
        description: "Ocorreu um erro na operação, tente novamente mais tarde",
        type: "error",
      });
    }
  }, [receipt, loadingDownload]);

  return (
    <Container noModal={!!noModal ? noModal : false} ref={componentRef}>
      <ReceiptHeader id="print-hidden">
        <CompanyLogo isWhiteLogo={true} width={200} height={55}></CompanyLogo>
        {!noModal && (
          <div style={{ position: "absolute", right: 0 }}>
            <IconBtn title="Fechar" onClick={onClose}>
              <Close />
            </IconBtn>
          </div>
        )}
      </ReceiptHeader>
      <PrintContent receipt={{ ...receipt, type: parsedType }} />
      <Footer id="print-hidden">
        {type !== "refound" && (
          <div onClick={handlePDFDownload}>
            Baixar comprovante
            <IconBtn
              style={{ marginLeft: 5 }}
              title="baixar comprovante"
              disabled={loadingDownload}
            >
              {loadingDownload ? (
                <CircularProgress
                  style={{
                    color: "rgb(0, 150, 255)",
                    height: 30,
                    width: 30,
                  }}
                />
              ) : (
                <GetApp />
              )}
            </IconBtn>
          </div>
        )}
        <div onClick={handlePrint}>
          Imprimir comprovante
          <IconBtn style={{ marginLeft: 5 }} title="imprimir comprovante">
            <PrintOutlined />
          </IconBtn>
        </div>
      </Footer>
    </Container>
  );
};

export const PrintContent = ({ receipt }) => {
  return (
    <Content className="printcontent">
      <ReceiptTitle id="print-title">
        <div>
          <h3>{typeReceipt[receipt.type]}</h3>
          <h2>{formatCurrency(receipt.amount)}</h2>
          <h4>
            Realizada em {moment(receipt.date).format("DD/MM/YYYY [às] HH:mm")}
          </h4>
        </div>
      </ReceiptTitle>
      <ReceiptDetailsContent id="print">
        {receipt.details &&
          receipt.details.map((objDetail, index) => (
            <div key={index}>
              <Border />
              <h4>{objDetail.value}</h4>
              {objDetail?.items.map((obj) => (
                <div>
                  {!!obj.value && (
                    <h4>
                      {`${obj.title ? obj.title + ":" : ""}`}
                      <p>{obj.value}</p>
                    </h4>
                  )}
                </div>
              ))}
            </div>
          ))}
        <br />
        <Border /> <br />
      </ReceiptDetailsContent>
      <ReceiptFooter>
        <p>Central de relacionamento</p>
        <p>Capitais e regiões metropolitanas</p>
        <p>{receipt.supportPhone}</p>
      </ReceiptFooter>
    </Content>
  );
};
export default ReceiptDetails;
