import {
  ArrowLeft,
  DeleteOutline,
  Dialpad,
  MoreVert,
  QrCode,
} from "@mui/icons-material";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { OrganizerCardContainer, OrganizerCardDetail } from "../styles";
import CustomButton from "../CustomButton";
import CachedIcon from "@mui/icons-material/Cached";

import CashoutIcon from "../../../../Assets/organize_cashout.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import OutrosIcon from "../../../../Assets/organize_outros.png";
import {
  convertToCents,
  formatCurrency,
  simpleConvertToCents,
} from "../../../../Utils/formatters";
import QrCodeIcon from "@mui/icons-material/QrCode";
import AddAmountIcon from "@mui/icons-material/AddBusiness";
import SaqueAmountIcon from "@mui/icons-material/PointOfSale";

import HomeIcon from "@mui/icons-material/Home";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import PixIcon from "@mui/icons-material/Pix";
import CodeIcon from "@mui/icons-material/Code";
import GroupIcon from "@mui/icons-material/Group";
import { useEffect, useState } from "react";
import { DateFilter, ReceiptList } from "../../../../Components/Molecules";
import { useTransactionsData } from "../../../../Hooks";
import { useFilter } from "../../../../Hooks/useFilter";
import { PaperHeader } from "../../Receipts/styles";
import { OperatorsTableList, useOperators } from "../../Operators";
import QRCode from "react-qr-code";
import { useToast } from "../../../../Hooks/toast";
import {
  useSubAccountCreateQRCode,
  useSubAccountGetQRCode,
} from "../../../../Hooks/useSubAccounts";
import { formatInputValue } from "../../../../Utils/formatInputValue";
import ManageBalanceModal from "../../../../Components/Molecules/ManageBalanceModal";
import { isDevelopmentOnly } from "../../../../Utils/developmentFeature";
import ApiTableList from "../ApiKeysTable";

const DetailsOrganizer = ({ selectedOrganizer, handleBack }) => {
  const matches = useMediaQuery("(min-width: 1220px)");
  const [menuSelected, handleMenuSelected] = useState("Resumo");
  const [amount, setAmount] = useState("");
  const { operatorsList, handleSelectedOperator } = useOperators({
    subAccountId: selectedOrganizer.subAccountId,
  });
  const [qrCode, handleQrcode] = useState("");

  const { getFilterObj } = useFilter();
  const extractFilterObj = getFilterObj("extract");

  const { addToast } = useToast();

  const [page, setPage] = useState(1);

  const {
    handleGET: fetchSubAccountDetails,
    loading,
    state: subAccountData,
  } = useTransactionsData();

  const {
    handleGET: fetchSubAccountQRCode,
    loading: _0,
    state: subAccountQRCode,
  } = useSubAccountGetQRCode();

  const {
    handlePOST: postSubAccountQRCode,
    loading: loadingCreateQRCode,
    state: subAccountQRCodeResponse,
    success: successQRCode,
  } = useSubAccountCreateQRCode();

  const handleSetToFirstPage = () => {
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleCopy = async (value) => {
    try {
      await navigator.clipboard.writeText(value);

      addToast({
        title: "Successo",
        description: "Chave copiada com sucesso.",
        type: "success",
      });
    } catch (error) {
      console.error("Error copying to clipboard:", error);
    }
  };

  useEffect(() => {
    if (subAccountQRCodeResponse?.data) {
      addToast({
        title: "Successo",
        description: "QRCode criado com sucesso.",
        type: "success",
      });
      handleQrcode(subAccountQRCodeResponse?.data.qrcode);
      setAmount(subAccountQRCodeResponse?.data.amount);
    }
  }, [subAccountQRCodeResponse?.data]);

  useEffect(() => {
    if (menuSelected === "QRcode") {
      fetchSubAccountQRCode({ id: selectedOrganizer.subAccountId });
    }
  }, [selectedOrganizer.subAccountId, menuSelected]);

  useEffect(() => {
    const queryParameters = {
      is_paged_result: true,
      page: page,
      page_size: 15,
      type: "all",
      sub_account_id: selectedOrganizer.subAccountId,
      ...extractFilterObj,
    };

    fetchSubAccountDetails({ ...queryParameters });
  }, [extractFilterObj, page, selectedOrganizer.subAccountId]);

  useEffect(() => {
    if (subAccountQRCode) {
      handleQrcode(subAccountQRCode.qrcode);
      setAmount(subAccountQRCode.amount);
    }
  }, [subAccountQRCode]);

  if (!subAccountData) {
    return null;
  }

  return (
    <Grid container spacing={2}>
      <Grid item md={3}>
        <OrganizerCardContainer>
          <Grid container marginBottom={4}>
            <CustomButton startIcon={<ArrowLeft />} onClick={handleBack}>
              Voltar
            </CustomButton>
          </Grid>
          <Grid
            container
            xs={12}
            spacing={2}
            flexDirection="row"
            marginRight={0}
          >
            <Grid item xs={12}>
              <CustomButton
                onClick={() => handleMenuSelected("Resumo")}
                fullWidth
                variant={menuSelected === "Resumo" ? "contained" : "outlined"}
                startIcon={<HomeIcon />}
              >
                Resumo
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={() => handleMenuSelected("Extrato")}
                fullWidth
                variant={menuSelected === "Extrato" ? "contained" : "outlined"}
                startIcon={<DataSaverOffIcon />}
              >
                Extrato
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={() => handleMenuSelected("QRcode")}
                fullWidth
                variant={menuSelected === "QRcode" ? "contained" : "outlined"}
                startIcon={<QrCode />}
              >
                QRCode
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={() => handleMenuSelected("API")}
                fullWidth
                variant={menuSelected === "API" ? "contained" : "outlined"}
                startIcon={<CodeIcon />}
              >
                API
              </CustomButton>
            </Grid>
            <Grid item xs={12}>
              <CustomButton
                onClick={() => handleMenuSelected("Operadores")}
                fullWidth
                variant={
                  menuSelected === "Operadores" ? "contained" : "outlined"
                }
                startIcon={<GroupIcon />}
              >
                Operadores
              </CustomButton>
            </Grid>
          </Grid>
        </OrganizerCardContainer>
      </Grid>
      <Grid item md={9}>
        {menuSelected === "Resumo" && (
          <OrganizerCardDetail>
            <Grid container>
              <Grid container marginBottom="12px">
                <SaldoCard
                  title={"Saldo do ponto de venda: "}
                  name={selectedOrganizer.name}
                  amount={selectedOrganizer.available}
                />
              </Grid>
              {isDevelopmentOnly && (
                <ManageBalanceModal
                  pointSaleName={selectedOrganizer.name}
                  pointSaleAmount={selectedOrganizer.available}
                  subAccountId={selectedOrganizer.subAccountId}
                >
                  {({ handleOpenModal }) => (
                    <Grid container spacing={2}>
                      <Grid item>
                        <CustomButton
                          startIcon={<AddAmountIcon />}
                          onClick={() => handleOpenModal("add-amount")}
                        >
                          Adicionar valor
                        </CustomButton>
                      </Grid>
                      <Grid item>
                        <CustomButton
                          startIcon={<SaqueAmountIcon />}
                          onClick={() => handleOpenModal("withdraw-amount")}
                        >
                          Resgatar valor
                        </CustomButton>
                      </Grid>
                    </Grid>
                  )}
                </ManageBalanceModal>
              )}
              {/* <Grid display="flex" marginTop="12px">
                <Grid item sm={4} marginRight="12px">
                  <IconCard
                    icon={CashoutIcon}
                    title="PIX CASHOUT"
                    totalAmount={3900900}
                    percent={"0.0"}
                  />
                </Grid>

                <Grid item sm={4}>
                  <IconCard
                    icon={OutrosIcon}
                    title="PIX CASHIN"
                    totalAmount={3900900}
                    percent={"0.0"}
                  />
                </Grid>
              </Grid> */}
            </Grid>
          </OrganizerCardDetail>
        )}
        {menuSelected === "Extrato" && (
          <OrganizerCardDetail>
            <PaperHeader
              style={{
                flexDirection: matches ? "row" : "column",
              }}
            >
              {subAccountData !== undefined && (
                <DateFilter
                  tableName={"extract"}
                  setPageToFirst={handleSetToFirstPage}
                  hasData={Object.keys(subAccountData).length > 0}
                  subAccountId={selectedOrganizer.subAccountId}
                />
              )}
            </PaperHeader>
            <ReceiptList
              itens={subAccountData}
              loading={loading.GET}
              type="extract"
              title=""
              headerLink={""}
              hasFilter={""}
              handleChangePage={handleChangePage}
              page={page}
            />
          </OrganizerCardDetail>
        )}
        {menuSelected === "QRcode" && (
          <OrganizerCardDetail>
            {qrCode ? (
              <Grid
                container
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <QRCode value={qrCode} />
                </Grid>
                <Grid item>
                  <TextField
                    value={qrCode}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => handleCopy(qrCode)}
                          disabled={!qrCode}
                        >
                          <ContentCopyIcon />
                        </IconButton>
                      ),
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item>
                  <SaldoCard title={""} amount={amount} />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography>
                    Para criar um QRCODE estático insira um valor
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    fullWidth
                    label="Valor do qrcode"
                    variant="outlined"
                    name="amont"
                    value={amount}
                    onChange={(e) =>
                      setAmount(formatInputValue(e.target.value))
                    }
                  />
                </Grid>
                <Grid item>
                  <CustomButton
                    startIcon={<QrCodeIcon />}
                    onClick={() =>
                      postSubAccountQRCode({
                        amount: simpleConvertToCents(amount),
                        id: selectedOrganizer.subAccountId,
                      })
                    }
                  >
                    Criar QRCODE
                  </CustomButton>
                </Grid>
              </Grid>
            )}
          </OrganizerCardDetail>
        )}
        {menuSelected === "API" && (
          <OrganizerCardDetail>
            <ApiTableList
              apiList={[]}
              handleCopy={handleCopy}
              subAccountId={selectedOrganizer.subAccountId}
            />
          </OrganizerCardDetail>
        )}
        {menuSelected === "Operadores" && (
          <OrganizerCardDetail>
            <OperatorsTableList
              operatorsList={operatorsList}
              handleSelectedOperator={handleSelectedOperator}
              isHiddenSubAccountLink={true}
            />
          </OrganizerCardDetail>
        )}
      </Grid>
    </Grid>
  );
};

const IconCard = ({ icon, title, totalAmount, percent }) => {
  return (
    <OrganizerCardDetail>
      <Grid container spacing={2} padding={1} alignItems="center">
        <Grid item xs={3}>
          <img src={icon} alt="icon" />
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                color="#5B6B79"
                fontWeight={600}
                lineHeight="2.0em"
              >
                {title}
              </Typography>
            </Grid>
            <Grid container xs={12} justifyContent="space-between">
              <Grid item>
                <strong>{formatCurrency(totalAmount)}</strong>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </OrganizerCardDetail>
  );
};

const SaldoCard = ({ title, amount, name = "" }) => {
  const eventRefreshBalance = new Event("refresh-balance");

  return (
    <Grid>
      <Grid>
        <Typography
          lineHeight={"3em"}
          color="#5B6B79"
          fontWeight={400}
          marginTop={1}
        >
          {title} <strong>{name}</strong>
        </Typography>
      </Grid>
      <Grid>
        <Typography fontSize="3em" alignItems="center">
          {formatCurrency(amount)}{" "}
          <IconButton
            size="large"
            color="primary"
            onClick={() => window.dispatchEvent(eventRefreshBalance)}
          >
            <CachedIcon />
          </IconButton>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DetailsOrganizer;
