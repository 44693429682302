import { ReportButton } from "../ReportDownload/styles";
import { ButtonGroup } from "@mui/material";
import { useInvoicesDownload } from "../../../Hooks";
import { PrintContent } from "../ReceiptModal/receiptDetails";
import { Loading } from "../../Atoms";

const InvoicesDownload = ({ hasData, invoicesData }) => {
  const { fetchAllInvoicesByRange, preparingFiles } = useInvoicesDownload();

  if (hasData.pageCount === 0) {
    return <></>;
  }

  return (
    <>
      <div
        style={{ height: 0, zIndex: -999, position: "absolute", left: -999999 }}
      >
        {invoicesData &&
          invoicesData.map((invoice, index) => (
            <div style={{ display: "flex" }} key={index}>
              <PrintContent receipt={invoice.receipt} key={+new Date()} />
            </div>
          ))}
      </div>

      <ButtonGroup variant="contained" aria-label="split button">
        <ReportButton onClick={fetchAllInvoicesByRange} style={{ width: 200 }}>
          {preparingFiles === true ? (
            <>
              <Loading />
              <span style={{ fontSize: 12 }}>Preparando PDF...</span>
            </>
          ) : (
            "Baixar comprovantes"
          )}
        </ReportButton>
      </ButtonGroup>
    </>
  );
};

export default InvoicesDownload;
