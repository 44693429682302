import { ArrowBack } from "@mui/icons-material";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CompanyLogo, Button } from "../../../Components/Atoms";
import { Container, Content, VerificationContainer } from "./styles";
import { AuthContext } from "../../../Contexts/Auth";
import { Grid, Input, Typography } from "@mui/material";
import useCallAxios from "../../../Hooks/useCallAxios";
import { Error } from "../../../Components/Atoms/Input/styles";
import { useToast } from "../../../Hooks/toast";
// import MetaDecorator from "../../components/MetaDecorator";

const ResetPassword: React.FC = () => {
  const history = useHistory();
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const { firstStepData } = stateAuthContext;

  const [passwordOld, handlePasswordOld] = useState("");
  const [passwordNew, handlePasswordNew] = useState("");

  const params = new URLSearchParams(history.location.search);
  const token = params.get("token");

  const { addToast } = useToast();

  const {
    fetchPOST: fetchResetPassword,
    requestLoading: loading,
    requestData: operatorsListResponse,
    requestSuccess: successPostResetSenha,
  } = useCallAxios({ context: "reset" });

  useEffect(() => {
    if (successPostResetSenha.POST) {
      history.push("/");
      addToast({
        title: "Successo",
        description: "Senha alterada com sucesso.",
        type: "success",
      });
    }
  }, [successPostResetSenha.POST]);

  return (
    <Container>
      <Content>
        <VerificationContainer>
          <CompanyLogo width={200} height={60} />
          <p>Crie sua nova senha</p>
          <p>Informe abaixo sua nova senha de acesso:</p>
          <Grid
            container
            spacing={2}
            flexDirection="column"
            alignItems="center"
          >
            <Grid item>
              <Input
                value={passwordOld}
                type="password"
                name="passwordOld"
                placeholder="Senha"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handlePasswordOld(e.target.value);
                }}
              />
            </Grid>
            <Grid item>
              <Input
                value={passwordNew}
                type="password"
                name="passwordNew"
                placeholder="Nova senha"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  handlePasswordNew(e.target.value);
                }}
              />
            </Grid>
            {passwordNew !== "" && passwordNew !== passwordOld && (
              <Typography variant="subtitle2" color="red">
                As senhas precisam ser iguais.
              </Typography>
            )}
            <Grid item>
              <Button
                onClick={() =>
                  fetchResetPassword({
                    body: {
                      token,
                      password: passwordOld,
                      confirmationPassword: passwordNew,
                    },
                  })
                }
              >
                Redefinir senha
              </Button>
            </Grid>
          </Grid>
          <Button
            action={"return"}
            onClick={() => {
              history.push("/");
              dispatchAuthContext({
                type: "clear",
              });
            }}
          >
            Voltar
          </Button>
        </VerificationContainer>
      </Content>
    </Container>
  );
};

export default ResetPassword;
