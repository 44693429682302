import React from 'react';
import {
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { ArrowBack } from "@mui/icons-material";
import { useState } from "react";
import LogoBrasilCashShort from "../../../Assets/brasilcash@logo_short.png";
import LogoNewUser from "../../../Assets/new_user@logo_short.png";
import { Button } from "../../../Components/Atoms";
import { useMediaQuery } from "@mui/material";
import { BCStep } from "./brasilCashStep";
import { OtherBanksStep } from "./otherAccountStep";
import { FinalStep } from "./finalStep";
import { ScreenGreeting } from "../ScreenGreeting";
import { useGetSiteOptions } from "../../../Hooks/WhiteLabel";
import { loadItem } from "../../../Utils";
import { ContactStep } from "./contactsStep";
import {
  BatchTransfer
} from './batchTransfer'
import { useReceiveValidateCsvTransfers } from '../../../Hooks/useReceiveValidateCsvTransfers';
import { ModalLoader } from "../../Atoms/ModalLoader";


export const TransferStepper = ({ onClose }) => {
  const classes = useStyles();
  const [typeTransfer, setTypeTransfer] = useState('');
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [responseData, setResponseData] = useState();
  const matches = useMediaQuery("(min-width: 1220px)");
  const [step, setStep] = useState(0);
  const { title } = loadItem("Options");

  const handleSelect = (value) => {
    setTypeTransfer(value);
    setStep(1);
  };

  const handleSteps = (value) => {
    const { status = null, step, state = {} } = value;
    setStep(step);
    setResponseData(state);
    setTransactionStatus(status);
  };

  const handleClose = () => {
    setStep(0);
    setTypeTransfer("");
    setTransactionStatus(false);
    setResponseData({});
    onClose();
  };

  //SEND CSV TRANSFERS
  const { 
    csvTransfers, 
    loading: sendFileLoading, 
    sendFile,
    setCsvTransfers

  } = useReceiveValidateCsvTransfers()

  const inputRef = React.useRef(null);

  const handleSelectedFile = React.useCallback(async e => {
    const [file] = e.target.files;

    const maxAllowedSize = 1 * 1024 * 1024; //MÁXIMO PERMITIDO 1MB;

    if(!file) return;

    if(file.size >= maxAllowedSize) {
      alert("É permitido somente arquivos Csv delimitados por ';' de até 1MB");
    }

    sendFile(file)

    setStep(1);
    setTypeTransfer('csv');

  }, [step, typeTransfer])


  const step1 = (
    <>
      <List
        dense
        style={{
          paddingBottom: 0,
          borderRadius: 5,
          height: "100%",
        }}
      >
        <ListItem
          style={{
            borderBottom: "1px solid #e0e0e0",
            height: "100%",
            padding: 20,
            flexDirection: matches ? "row" : "column",
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: 0,
            }}
          >
            <img src={LogoBrasilCashShort} height={30} width="auto" />
          </ListItemIcon>
          <ListItemText
            style={{
              paddingTop: "5px",
              height: "100%",
            }}
            primary={
              <Typography variant="h6">
                Transferir para contas {title}
              </Typography>
            }
          />
          <ListItemSecondaryAction
            style={{
              justifyContent: "start",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleSelect("internal")}
            >
              Transferir
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          style={{
            borderBottom: "1px solid #e0e0e0",
            height: "100%",
            padding: 20,
            flexDirection: matches ? "row" : "column",
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: 0,
            }}
          >
            <img src={LogoNewUser} height={30} width="auto" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6">
                Transferir para outra instituição bancária
              </Typography>
            }
          />
          <ListItemSecondaryAction
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleSelect("external")}
            >
              Transferir
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem
          style={{
            borderBottom: "1px solid #e0e0e0",
            height: "100%",
            padding: 20,
            flexDirection: matches ? "row" : "column",
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: 0,
            }}
          >
            <img src={LogoBrasilCashShort} height={30} width="auto" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6">
                Transferencia em lote entre contas Brasil Cash
              </Typography>
            }
          />
          <ListItemSecondaryAction
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <div style={{ marginRight: '20px' }}>
              <Link href="https://bcpagdocs.blob.core.windows.net/batch/batch-exemple.csv" download target='_blank'>Exemplo</Link>
            </div>
            
            <Button
              color="primary"
              variant="outlined"
              onClick={() => inputRef.current?.click()}
            >
              Enviar Arquivo
            </Button>
          </ListItemSecondaryAction>
        </ListItem>
        {/* <ListItem
          style={{
            borderBottom: "1px solid #e0e0e0",
            height: "100%",
            padding: 20,
            flexDirection: matches ? "row" : "column",
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              alignItems: "flex-start",
              padding: 0,
            }}
          >
            <img src={LogoNewUser} height={30} width="auto" />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="h6">Transferir para contato</Typography>
            }
          />
          <ListItemSecondaryAction
            style={{
              height: "100%",
              alignItems: "center",
              display: "flex",
            }}
          >
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleSelect("contacts")}
            >
              Transferir
            </Button>
          </ListItemSecondaryAction>
        </ListItem> */}
      </List>
    </>
  );

  return (
    <>
      <ModalLoader
        open={sendFileLoading.POST}
        text="O arquivo está sendo analisado, aguarde..."
      />
      {step !== 2 ? (
        <>
         <input type="file" accept=".csv" onClick={e => e.currentTarget.value = ""} onChange={handleSelectedFile} hidden ref={inputRef}/>
          <ScreenGreeting
            title={"Transferir"}
            subTitle={`Transfira para contas ${title} ou entre outras instituições bancárias`}
          />
          <Paper elevation={3} className={classes.paper}>
            <div className={classes.listContainer}>
              {step === 0 && step1}

              
              {step === 1 && typeTransfer === "internal" && (
                <BCStep
                  refreshSteps={handleSteps}
                  status={(e) => setTransactionStatus(e.current.target.value)}
                />
              )}


              {step === 1 && typeTransfer === "external" && (
                <OtherBanksStep
                  refreshSteps={handleSteps}
                  status={(e) => setTransactionStatus(e.current.target.value)}
                />
              )}


              {step === 1 && typeTransfer === 'csv' && (
                <BatchTransfer
                  transfers={csvTransfers}
                  onClose={() => {
                    setStep(0);
                    setTypeTransfer('');
                    setCsvTransfers({
                      items: [],
                      totalAmount: 0,
                      totalTransfers: 0
                    })

                  }}
                  file={inputRef.current?.files[0]}
                />
              )}

              {/* {step === 1 && typeTransfer === "contacts" && (
                <ContactStep
                  refreshSteps={handleSteps}
                  status={(e) => setTransactionStatus(e.current.target.value)}
                />
              )} */}
            </div>
            <div className={classes.buttons}>
              {step === 0 && (
                <div className={classes.header}>
                  <p className={classes.backButton} onClick={handleClose}>
                    <ArrowBack></ArrowBack>
                    Voltar para transferências
                  </p>
                </div>
              )}
            </div>
          </Paper>
        </>
      ) : (
        <FinalStep
          refreshSteps={handleClose}
          transactionStatus={transactionStatus}
          transactionData={responseData}
        />
      )}
    </>
  );
};
