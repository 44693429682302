import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { loadItem, removeItem, saveItem } from "../Utils";

const userId = loadItem("userId");
const userLanguage = loadItem("userLanguage");
const userName = loadItem("userName");
const userQualified = loadItem("userQualified");
const userRole = loadItem("userRole");
const userSubAccountId = loadItem("userSubAccountId");

const defaultState = {
  userId: "",
  userLanguage: "pt-BR",
};

const initialState = {
  userId: userId || "",
  userLanguage: userLanguage || "pt-BR",
  userName: userName || "",
  userQualified: userQualified || "",
  userRole: userRole || "",
  userSubAccountId: userSubAccountId || "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setUserOperator":
      setItem("userQualified");
      setItem("userRole");
      setItem("userSubAccountId");

      return {
        ...state,
        userQualified: action.userQualified,
        userRole: action.userRole,
        userSubAccountId: action.userSubAccountId,
      };

    case "setSettingsUser":
      saveItem(action.userId, "userId");
      saveItem(action.userName, "userName");
      saveItem("pt-BR", "userLanguage");

      return {
        ...state,
        userId: action.userId,
        userName: action.userName,
      };
    case "clear":
      removeItem("userId");
      removeItem("userLanguage");
      removeItem("userName");

      return defaultState;
    default:
      throw new Error("Unexpected action");
  }
};

export const UserContext = React.createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
