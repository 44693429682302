import styled from "styled-components";

export const StoreButton = styled.div`
  display: inline-block;
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
`;

export const StoreAnchor = styled.a`
  background: url(${(props) => props.storeUrl}) no-repeat;
  background-size: contain;
  display: inline-block;
  overflow: hidden;
  text-decoration: none;
  height: 100%;
  width: 100%;
  padding: 5px;
`;
