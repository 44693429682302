import { normalizeTicketsData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useTicketsData = () => {
  const context = `ticketStatement`;
  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    fetchGET,
    fetchPOST,
    fetchDELETE,
  } = useCallAxios({
    context,
  });
  const handleGET = (params, id = null, afterID = null) => {
    fetchGET({ context, id, afterID, queryParameters: filterQuery(params) });
  };

  const handlePOST = (params) => {
    fetchPOST({ body: params });
  };

  const handleDELETE = (id) => {
    fetchDELETE({ id, noRefreshData: true });
  };

  return {
    reset,
    loading: requestLoading,
    state: requestData.data,
    success: requestSuccess,
    handleGET,
    handlePOST,
    handleDELETE,
  };
};
