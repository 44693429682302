import { Button } from "@mui/material";

const CustomButton = ({ children, ...props }) => {
  return (
    <Button
      sx={{
        borderRadius: 3,
        border: props.variant === "contained" ? "none" : "solid 1px #D3E1F5",
        padding: "8px 12px",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      {...props}
      startIcon={null}
    >
      {props.startIcon}
      <p
        style={{
          fontSize: 14,
          fontWeight: 600,
          color: props.variant === "contained" ? "#fff" : "#050624",
          textTransform: "capitalize",
          marginLeft: 16,
        }}
      >
        {children}
      </p>
    </Button>
  );
};

export default CustomButton;
