import { Paper, useMediaQuery } from "@mui/material";

import qs from "qs";
import { useCallback, useEffect, useState } from "react";
import intl from "react-intl-universal";
import { useHistory } from "react-router";
import {
  DateFilter,
  ScreenGreeting,
  TicketsList,
  TicketStepper,
} from "../../../Components/Molecules";
import { useTicketsData } from "../../../Hooks";
import {
  Container,
  ContentContainer,
  FilterContainer,
  ListContainer,
  PaperDiv,
  PaperHeader,
} from "./styles";
import { useFilter } from "../../../Hooks/useFilter";
import { TableFilter } from "../../../Components/Molecules/Filters";

export const Tickets = () => {
  const parsedQS = qs.parse(location.search.replace("?", ""));
  const history = useHistory();
  const matches = useMediaQuery("(min-width: 1220px)");
  const [page, setPage] = useState(1);
  const [addTicketStatus, setAddTicketStatus] = useState(false);
  const {
    state: ticketsData,
    handleGET: fetchTicketsGET,
    handleDELETE: fetchTicketsDELETE,
    loading,
    success: successTickets,
  } = useTicketsData();

  const { getFilterObj } = useFilter();
  const extractFilterObj = getFilterObj("tickets");

  const handleSetToFirstPage = () => {
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleRefreshList = useCallback(() => {
    fetchTicketsGET({
      is_paged_result: true,

      page_size: 15,
      ...extractFilterObj,
    });
  }, []);

  useEffect(() => {
    const queryParameters = {
      is_paged_result: true,
      page_size: 15,
      ...extractFilterObj,
    };
    fetchTicketsGET({ ...queryParameters });
  }, [extractFilterObj, page]);

  useEffect(() => {
    Boolean(parsedQS.newTicket) === true && setAddTicketStatus(true);
  }, [parsedQS]);

  return (
    <Container>
      {!addTicketStatus ? (
        <>
          <ScreenGreeting
            title={intl.get(`phrases.myTickets`)}
            subTitle={intl.get(`phrases.emittedTicketDetails`)}
            hasButton
            buttonTitle={intl.get(`phrases.newTicket`)}
            handleClick={() => setAddTicketStatus(true)}
          />
          <FilterContainer>
            <TableFilter
              tableName="tickets"
              setPageToFirst={handleSetToFirstPage}
            />
          </FilterContainer>
          <Paper elevation={3}>
            <ContentContainer>
              <PaperHeader
                style={{
                  flexDirection: matches ? "row" : "column",
                }}
              >
                {ticketsData !== undefined && (
                  <DateFilter
                    tableName={"tickets"}
                    setPageToFirst={handleSetToFirstPage}
                    hasData={Object.keys(ticketsData).length > 0}
                  />
                )}
              </PaperHeader>
              <ListContainer>
                <TicketsList
                  itens={ticketsData}
                  loading={loading.GET}
                  refreshList={handleRefreshList}
                />
              </ListContainer>
            </ContentContainer>
          </Paper>
        </>
      ) : (
        <>
          <ScreenGreeting
            title={intl.get(`words.ticket`)}
            subTitle={intl.get(`phrases.ticketForWho`)}
          />

          <PaperDiv>
            <TicketStepper
              onClose={() => {
                setAddTicketStatus(false);
                history.push("/boletos");
              }}
              refreshList={handleRefreshList}
            />
          </PaperDiv>
        </>
      )}
    </Container>
  );
};
